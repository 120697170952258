import { flowRight, range } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import { ArrowLeftIcon } from '../icons/arrow-left-icon';
import { ArrowRightIcon } from '../icons/arrow-right-icon';
import { handleEnterKeyUp } from '../../services/accessibility';
import forDirection from '../../hoc/for-direction';
import withFontClassName from '../../hoc/with-font-class-name';
import Link from '../link/internal-link';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import styles from './pagination.scss';

const ArrowLeftIconRtl = forDirection(ArrowLeftIcon, ArrowRightIcon);
const ArrowRightIconRtl = forDirection(ArrowRightIcon, ArrowLeftIcon);

export class Pagination extends Component {
  static propTypes = {
    entityCount: PropTypes.number.isRequired,
    showPerPage: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    activeClass: PropTypes.string,
    arrowClass: PropTypes.string,
    innerClass: PropTypes.string,
    arrowLeft: PropTypes.node,
    arrowRight: PropTypes.node,
    className: PropTypes.string,
    page: PropTypes.number,
    contentFontClassName: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    buildPageUrl: PropTypes.func,
    changePageOnClick: PropTypes.bool,
    isMobile: PropTypes.bool,
  };

  static defaultProps = {
    arrowLeft: <ArrowLeftIconRtl />,
    arrowRight: <ArrowRightIconRtl />,
    changePageOnClick: true,
  };

  constructor(props) {
    super(props);

    const { entityCount, showPerPage, page } = props;
    this.state = {
      currentPage: page || 1,
      numberCount: Math.ceil(entityCount / (showPerPage || 1)),
    };

    this.handleClick = this.handleClick.bind(this);
  }

  componentWillReceiveProps({ entityCount, showPerPage, page }) {
    if (entityCount && showPerPage) {
      const numberCount = Math.ceil(entityCount / (showPerPage || 1));
      this.setState({ numberCount });
    }
    if (page) {
      this.setState({ currentPage: page });
    }
  }

  renderNumbers() {
    const { currentPage, numberCount } = this.state;

    if (numberCount < 5) {
      return range(1, numberCount + 1).map(page => this.renderPage(page));
    }

    if (currentPage + 3 > numberCount) {
      return range(numberCount - 4, numberCount + 1).map(page => this.renderPage(page));
    }

    if (currentPage > 3) {
      return range(currentPage - 2, currentPage + 3).map(page => this.renderPage(page));
    }

    return range(1, 6).map(page => this.renderPage(page));
  }

  renderCompactNumbers() {
    const { changePageOnClick, showPerPage, onChange } = this.props;
    const { currentPage, numberCount } = this.state;

    return (
      <div className={styles.compactNumbers}>
        {`${currentPage}/${numberCount}`}
        <select
          className={styles.compactNumbersSelect}
          onChange={ev => {
            const page = ev.target.value;
            if (changePageOnClick) {
              this.setState({ currentPage: page });
            }
            const from = (page - 1) * showPerPage;
            const to = page * showPerPage;
            onChange({ page, from, to, buttonType: Pagination.PAGE });
          }}
          value={currentPage}
          data-hook="pagination__select"
        >
          {Array.from({ length: numberCount }).map((_, i) => (
            <option data-hook={`pagination__page_${i + 1}`} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </select>
      </div>
    );
  }

  renderPage(page) {
    const { activeClass, buildPageUrl, innerClass, t } = this.props;
    const isActive = page === this.state.currentPage;
    const pageClassName = classNames(styles.page, innerClass, 'button-hover-color', {
      [activeClass]: isActive,
      [styles.activePage]: isActive,
      'button-color': isActive,
    });
    const handleClick = !isActive && this.handleClick(page, Pagination.PAGE);
    const Component = buildPageUrl ? Link : 'div';
    const props = {};
    if (buildPageUrl) {
      props.to = buildPageUrl(page);
    }

    return (
      <Component
        key={page}
        className={pageClassName}
        onClick={handleClick}
        onKeyUp={handleEnterKeyUp(handleClick)}
        tabIndex={!isActive ? '0' : undefined}
        aria-label={t(isActive ? 'pagination.page-selected' : 'pagination.page', { page })}
        data-hook={`pagination__${page}`}
        {...props}
      >
        {page}
      </Component>
    );
  }

  renderFirst() {
    const { arrowClass, arrowLeft, buildPageUrl, isMobile, t } = this.props;
    const { currentPage } = this.state;

    const shouldShow = currentPage > 3 || isMobile;
    const isDisabled = isMobile && currentPage < 2;
    const handleClick = this.handleClick(1, Pagination.FIRST);
    const Component = buildPageUrl && !isDisabled ? Link : 'div';
    const props = {};
    if (buildPageUrl) {
      props.to = buildPageUrl(1);
    }

    if (shouldShow) {
      return (
        <Component
          className={classNames(styles.first, arrowClass, 'forum-icon-fill', {
            'button-hover-fill': !isDisabled,
          })}
          onClick={!isDisabled && handleClick}
          onKeyUp={handleEnterKeyUp(handleClick)}
          tabIndex="0"
          aria-label={t('pagination.first-page')}
          data-hook="pagination__first"
          disabled={isDisabled}
          {...props}
        >
          {arrowLeft}
          {arrowLeft}
        </Component>
      );
    }
    return null;
  }

  renderPrevious() {
    const { arrowClass, arrowLeft, buildPageUrl, isMobile, t } = this.props;
    const { currentPage } = this.state;

    const shouldShow = currentPage > 1 || isMobile;
    const isDisabled = isMobile && currentPage <= 1;

    const handleClick = this.handleClick(currentPage - 1, Pagination.PREVIOUS);
    const Component = buildPageUrl && !isDisabled ? Link : 'div';
    const props = {};
    if (buildPageUrl) {
      props.to = buildPageUrl(currentPage - 1);
    }

    if (shouldShow) {
      return (
        <Component
          className={classNames(styles.previous, arrowClass, 'forum-icon-fill', {
            'button-hover-fill': !isDisabled,
          })}
          onClick={!isDisabled && handleClick}
          onKeyUp={handleEnterKeyUp(handleClick)}
          tabIndex="0"
          aria-label={t('pagination.previous-page')}
          data-hook="pagination__previous"
          disabled={isDisabled}
          {...props}
        >
          {arrowLeft}
        </Component>
      );
    }
    return null;
  }

  renderNext() {
    const { arrowClass, arrowRight, buildPageUrl, isMobile, t } = this.props;
    const { currentPage, numberCount } = this.state;

    const shouldShow = currentPage < numberCount || isMobile;
    const isDisabled = isMobile && currentPage === numberCount;

    const handleClick = this.handleClick(currentPage + 1, Pagination.NEXT);
    const Component = buildPageUrl && !isDisabled ? Link : 'div';
    const props = {};
    if (buildPageUrl) {
      props.to = buildPageUrl(currentPage + 1);
    }

    if (shouldShow) {
      return (
        <Component
          className={classNames(styles.next, arrowClass, 'forum-icon-fill', {
            'button-hover-fill': !isDisabled,
          })}
          onClick={!isDisabled && handleClick}
          onKeyUp={handleEnterKeyUp(handleClick)}
          tabIndex="0"
          aria-label={t('pagination.next-page')}
          data-hook="pagination__next"
          disabled={isDisabled}
          {...props}
        >
          {arrowRight}
        </Component>
      );
    }
    return null;
  }

  renderLast() {
    const { arrowClass, arrowRight, buildPageUrl, isMobile, t } = this.props;
    const { currentPage, numberCount } = this.state;

    const shouldShow = currentPage < numberCount - 2 || isMobile;
    const isDisabled = isMobile && currentPage === numberCount;

    const handleClick = this.handleClick(numberCount, Pagination.LAST);
    const Component = buildPageUrl && !isDisabled ? Link : 'div';
    const props = {};
    if (buildPageUrl) {
      props.to = buildPageUrl(numberCount);
    }
    if (shouldShow) {
      return (
        <Component
          className={classNames(styles.last, arrowClass, 'forum-icon-fill', {
            'button-hover-fill': !isDisabled,
          })}
          onClick={!isDisabled && handleClick}
          onKeyUp={handleEnterKeyUp(handleClick)}
          tabIndex="0"
          aria-label={t('pagination.last-page')}
          data-hook="pagination__last"
          disabled={isDisabled}
          {...props}
        >
          {arrowRight}
          {arrowRight}
        </Component>
      );
    }
    return null;
  }

  handleClick(page, buttonType) {
    const { onChange, showPerPage, changePageOnClick } = this.props;
    return evt => {
      evt.preventDefault();
      if (changePageOnClick) {
        this.setState({ currentPage: page });
      }
      const from = (page - 1) * showPerPage;
      const to = page * showPerPage;
      onChange({ page, from, to, buttonType });
    };
  }

  render() {
    const {
      entityCount,
      showPerPage,
      className,
      contentFontClassName,
      isMobile,
      dataHook,
      t,
    } = this.props;

    if (entityCount <= showPerPage) {
      return null;
    }
    return (
      <div
        className={classNames(className, contentFontClassName, 'forum-text-color')}
        aria-label={t('pagination.pagination')}
        data-hook={dataHook}
      >
        {this.renderFirst()}
        {this.renderPrevious()}
        {isMobile ? this.renderCompactNumbers() : this.renderNumbers()}
        {this.renderNext()}
        {this.renderLast()}
      </div>
    );
  }
}

Pagination.FIRST = 'first';
Pagination.LAST = 'last';
Pagination.NEXT = 'next';
Pagination.PREVIOUS = 'previous';
Pagination.PAGE = 'page';

export default flowRight(withFontClassName, withTranslate)(Pagination);
