import PropTypes from 'prop-types';
import React from 'react';
import { flowRight } from 'lodash';
import classNames from 'classnames';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import { handleEnterAndSpaceKeyUp } from '../../services/accessibility';
import styles from './mobile-cta-button.scss';

const MobileCTAButton = ({
  onClick,
  contentFontClassName,
  label,
  dataHook,
  Icon,
  isSecondary,
  containerClassNames,
  t,
}) => {
  const className = classNames(
    styles.button,
    contentFontClassName,
    isSecondary ? 'button-border-color' : 'button-background-color',
    isSecondary ? 'button-color' : 'button-primary-text-color',
    isSecondary ? 'button-fill' : 'icon-fill',
    { [styles.isSecondary]: isSecondary },
  );
  return (
    <div className={classNames(styles.container, containerClassNames)}>
      <div
        className={className}
        onClick={onClick}
        tabIndex="0"
        onKeyUp={handleEnterAndSpaceKeyUp(onClick)}
        data-hook={dataHook}
        role="button"
        label={t(label)}
      >
        {Icon && <Icon className={styles.icon} />}
        {t(label)}
      </div>
    </div>
  );
};

MobileCTAButton.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  contentFontClassName: PropTypes.string.isRequired,
  dataHook: PropTypes.string,
  icon: PropTypes.element,
  isSecondary: PropTypes.bool,
};

export default flowRight(withFontClassName, withTranslate)(MobileCTAButton);
