import PropTypes from 'prop-types';
import React from 'react';
import MobileCtaButton from '../mobile-cta-button';
import { NotificationIcon } from '../icons/notification-icon';
import styles from './follow-card.scss';
import FollowCategoryHeadless from '../follow-category-headless';
import { NotificationFollowingIcon } from '../icons/notification-following-icon';
import ensureAuth from '../../hoc/ensure-auth';

const MobileCtaButtonWithAuth = ensureAuth(MobileCtaButton);

const FollowCard = ({ category, withoutPaddingTop }) => {
  return (
    <FollowCategoryHeadless category={category} data-hook="post-list-item__follow-button">
      {({ onClick, isSubscribed }) => (
        <MobileCtaButtonWithAuth
          Icon={isSubscribed ? NotificationFollowingIcon : NotificationIcon}
          label={
            isSubscribed
              ? 'category-actions.unsubscribe-category'
              : 'category-actions.subscribe-category'
          }
          dataHook="follow-category-cta"
          containerClassNames={withoutPaddingTop && styles.buttonContainer}
          isSecondary
          onClick={onClick}
        />
      )}
    </FollowCategoryHeadless>
  );
};

FollowCard.propTypes = {
  category: PropTypes.object,
  withoutPaddingTop: PropTypes.bool,
};

export default FollowCard;
