import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';

const CLASS_PREDICATES = {
  xs: w => w > 0 && w < 600,
  sm: w => w >= 600 && w < 980,
  md: w => w >= 980 && w < 1280,
  lg: w => w >= 1280 && w < 1920,
  xl: w => w >= 1920,
  'lt-sm': w => w < 600,
  'lt-md': w => w < 980,
  'lt-lg': w => w < 1280,
  'lt-xl': w => w < 1920,
  'gt-xs': w => w >= 600,
  'gt-sm': w => w >= 980,
  'gt-md': w => w >= 1280,
  'gt-lg': w => w >= 1920,
  'sidebar-off': w => w < 750,
  'sidebar-on': w => w >= 750,
};

const CLASS_LIST = Object.keys(CLASS_PREDICATES);

const getClassListForWidth = w =>
  CLASS_LIST.reduce((result, className) => {
    if (CLASS_PREDICATES[className](w)) {
      result.push(className);
    }
    return result;
  }, []);

class ResponsiveListener extends Component {
  constructor(props) {
    super(props);
    this.nodeRef = React.createRef();
  }

  componentDidMount() {
    this.classList = getClassListForWidth(this.props.width);
    this.resizeObserver = new ResizeObserver(entries => {
      const classList = getClassListForWidth(entries[0].contentRect.width);
      if (!isEqual(this.classList, classList)) {
        this.classList = classList;
        this.nodeRef.current.classList.remove(...CLASS_LIST);
        this.nodeRef.current.classList.add(...classList);
      }
    });
    this.resizeObserver.observe(this.nodeRef.current);
  }

  componentWillUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  render() {
    return (
      <div ref={this.nodeRef} className={classNames(getClassListForWidth(this.props.width))}>
        {this.props.children}
      </div>
    );
  }
}

ResponsiveListener.propTypes = {
  children: PropTypes.any,
  width: PropTypes.number,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  width: host.dimensions.width,
});

export default connect(mapRuntimeToProps)(ResponsiveListener);
