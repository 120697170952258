import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import styles from './typing-list-loader.scss';

const TypingListLoader = ({ t }) => (
  <span className={classNames(styles.loader, 'button-color')}>
    {t('typing-list-loader.typing')}
  </span>
);

TypingListLoader.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslate(TypingListLoader);
