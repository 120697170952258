import { flowRight } from 'lodash';
import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './new-content-indicator.scss';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_RECENT_ACTIVITY_CATS } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import IconTooltip from '../icon-tooltip';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';

class NewContentIndicator extends Component {
  renderDot = () => (
    <div aria-hidden className={styles.dotWrapper}>
      <span className={classNames(styles.dot, 'color-8-background')} />
    </div>
  );
  render() {
    const {
      className,
      hideDot,
      hideNumber,
      hideWord,
      hideBackground,
      noSpacing,
      inline,
      count,
      t,
      onClick,
      onMouseDown,
    } = this.props;
    if (!this.props.isRecentActivityCatsEnabled) {
      return null;
    }

    if (typeof count !== 'number' || count === 0) {
      // when count not a number or 0 means that there is not new content, thus we return null
      // this is so that every place that implement this doesn't need to do a check if it should render this or not
      return null;
    }

    return (
      <IconTooltip
        className={classNames(className, inline && styles.inline)}
        text={t(
          hideNumber
            ? 'new-content.new-post'
            : count === 1
            ? 'new-content.new-post-single'
            : 'new-content.new-posts',
          { count },
        )}
        onClick={onClick}
        onMouseDown={onMouseDown}
      >
        <div
          className={classNames(styles.container, 'color-8-text', {
            [styles.noSpacing]: noSpacing,
          })}
        >
          {!hideBackground && (
            <div className={classNames(styles.background, 'color-8-background')} />
          )}
          {!hideDot && this.renderDot()}
          {!hideNumber && count && (
            <span className={styles.number}>{count <= 99 ? count : '99'}</span>
          )}
          {!hideWord && <span className={styles.word}>{t('new-content.new')}</span>}
        </div>
      </IconTooltip>
    );
  }
}

NewContentIndicator.propTypes = {
  t: PropTypes.func,
  className: PropTypes.string,
  hideDot: PropTypes.bool,
  hideNumber: PropTypes.bool,
  hideWord: PropTypes.bool,
  hideBackground: PropTypes.bool,
  noSpacing: PropTypes.bool,
  inline: PropTypes.bool,
  count: PropTypes.number,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
};

export default flowRight(
  withExperiment({
    isRecentActivityCatsEnabled: EXPERIMENT_RECENT_ACTIVITY_CATS,
  }),
  withTranslate,
)(NewContentIndicator);
