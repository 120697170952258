import i18next from 'i18next';
import createI18nConfig from './create-i18n-config';

export const DEFAULT_I18N_NS = 'translation';

export function initI18n(lng, localeData) {
  i18next.init({
    ...createI18nConfig(),
    resources: { [lng]: { [DEFAULT_I18N_NS]: localeData } },
    lng,
  });
  return i18next;
}
