import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import { getSubcategories } from '../../../common/selectors/categories-selectors';
import { LAYOUT_TYPE_PINBOARD } from '../../constants/layout-types';
import { getIsShowSubcategoriesEnabled } from '../../selectors/app-settings-selectors';
import CategoryCardMobile from '../category-card-mobile';
import { HorizontalSeparator } from '../separator';
import styles from './category-list-mobile.scss';

const CategoryItem = ({
  category,
  key,
  subcategories,
  isViewsCountEnabled,
  isShowSubcategoriesEnabled,
  isFooterPostsEnabled,
  isLast,
  layoutType,
}) => {
  return (
    <div
      className={classNames(
        'forum-card-border-color',
        'forum-card-background-color',
        'forum-text-color',
      )}
      key={key}
    >
      <CategoryCardMobile
        category={category}
        isViewsCountEnabled={isViewsCountEnabled}
        isLarge={layoutType === LAYOUT_TYPE_PINBOARD}
      />
      {isShowSubcategoriesEnabled &&
        subcategories.map((subcategory, index) => {
          return (
            <React.Fragment key={index}>
              <HorizontalSeparator className={styles.shortSeparator} />
              <CategoryCardMobile
                category={subcategory}
                isViewsCountEnabled={isViewsCountEnabled}
                isLarge={layoutType === LAYOUT_TYPE_PINBOARD}
                isSubcategory
              />
            </React.Fragment>
          );
        })}
      {!isFooterPostsEnabled && isLast ? null : <HorizontalSeparator isLarge />}
    </div>
  );
};

const mapRuntimeToProps = (state, { category }, actions, host) => ({
  subcategories: getSubcategories(state, category._id),
  isShowSubcategoriesEnabled: getIsShowSubcategoriesEnabled(state, host.style),
});

const CategoryListItem = connect(mapRuntimeToProps)(CategoryItem);

const CategoryList = ({ categories, isViewsCountEnabled, layoutType, isFooterPostsEnabled }) => {
  const categoriesLength = categories.length;
  return (
    <div data-hook="category-list">
      {categories.map((category, index) => (
        <CategoryListItem
          key={index}
          isLast={index + 1 === categoriesLength}
          isFooterPostsEnabled={isFooterPostsEnabled}
          category={category}
          isViewsCountEnabled={isViewsCountEnabled}
          layoutType={layoutType}
        />
      ))}
    </div>
  );
};

CategoryList.propTypes = {
  categories: PropTypes.array.isRequired,
  isViewsCountEnabled: PropTypes.bool,
  isFooterPostsEnabled: PropTypes.bool,
  layoutType: PropTypes.string,
};

export default CategoryList;
