import numeral from 'numeral';

const DEFAULT_LANG = 'en';
const COMPACT_FORMAT = '0a';

const FILE_NAMES = {
  cs: 'cs',
  da: 'da-dk',
  de: 'de',
  es: 'es',
  fi: 'fi',
  fr: 'fr',
  hu: 'hu',
  it: 'it',
  ja: 'ja',
  nl: 'nl-nl',
  no: 'no',
  pl: 'pl',
  pt: 'pt-pt',
  ru: 'ru',
  th: 'th',
  tr: 'tr',
};

export const compactNumber = lang => {
  let numeralData;
  try {
    numeralData = require(`numeral/languages/${FILE_NAMES[lang]}`);
  } catch (e) {}

  if (lang !== DEFAULT_LANG && !numeralData) {
    return value => value;
  }

  numeral.language(lang, numeralData);
  return value =>
    numeral(value)
      .format(COMPACT_FORMAT)
      .toUpperCase();
};
