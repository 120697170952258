export const ROUTE_404 = '/404';
export const ROUTE_SEARCH = '/search(/:query)';
export const ROUTE_LOGIN = '/login';
export const ROUTE_ACCOUNT_SUSPENDED = '/account-suspended';
export const ROUTE_CREATE_POST = '/create-post';
export const ROUTE_CREATE_QUESTION = '/create-question';
export const ROUTE_COMMENT_DEEP_LINK = '/main/comment/:commentId';
export const ROUTE_CATEGORY = '/:categorySlug(/p-:page)';
export const ROUTE_CATEGORY_CREATE_POST = '/:categorySlug/create-post';
export const ROUTE_CATEGORY_CREATE_QUESTION = '/:categorySlug/create-question';
export const ROUTE_POST = '/:categorySlug/:postSlug(/p-:page)(/dl-:deepLinkData)';
export const ROUTE_POST_EDIT = '/:categorySlug/:postSlug/edit';
export const ROUTE_HOME = '/(p-:page)';

export const ROUTE_DEV_PLAYGROUND = '/dev-playground';
