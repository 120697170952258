import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Header from '../../components/header';
import HeaderBackButton from '../../components/header-back-button';
import withFontClassName from '../../hoc/with-font-class-name';
import styles from './search-mobile-header.scss';
import { VerticalSeparator } from '../../components/separator';
import SearchInput from '../../components/search-input/search-input';

const backButton = (
  <React.Fragment>
    <HeaderBackButton text="" className={styles.backButton} />
    <VerticalSeparator />
  </React.Fragment>
);

const SearchMobileHeader = ({ contentFontClassName, query, onQueryChange, ...otherProps }) => {
  const searchInput = (
    <div className={styles.searchInputContainer}>
      <SearchInput
        isFullWidth
        value={query}
        onClick={onQueryChange}
        inputClassName={classNames('forum-text-color', contentFontClassName)}
        borderClassName="forum-text-background-color"
        iconClassName="forum-icon-fill"
        showUnderline={false}
      />
    </div>
  );

  return <Header left={backButton} right={searchInput} className={styles.header} {...otherProps} />;
};

SearchMobileHeader.propTypes = {
  contentFontClassName: PropTypes.string.isRequired,
};

export default withFontClassName(SearchMobileHeader);
