import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import PostListMobileClassic from '../post-list-mobile-classic';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import withFontClassName from '../../hoc/with-font-class-name';
import styles from './footer-posts-mobile.scss';
import { TYPE_NEW_POSTS } from './types';

const FooterPostsMobile = ({ t, contentFontClassName, posts, onLikeClick, type }) => {
  return (
    <div className={classNames(styles.container)} data-hook="footer-posts">
      <div
        className={classNames(
          contentFontClassName,
          'forum-text-color',
          'forum-card-background-color',
          styles.title,
        )}
      >
        {t(type === TYPE_NEW_POSTS ? 'footer-posts.title' : 'footer-posts.related-posts')}
      </div>
      <PostListMobileClassic
        posts={posts}
        onLikeClick={onLikeClick}
        showCreatePostAction={false}
        showCategoryLink={false}
        showLockIcon={false}
        showMoreButton={false}
        showPinIcon={false}
        showBestAnswer={false}
        showViewCount={false}
        fullWidthSeparator
      />
    </div>
  );
};

FooterPostsMobile.propTypes = {
  t: PropTypes.func,
  contentFontClassName: PropTypes.string,
  posts: PropTypes.array,
  onLikeClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  onLikeClick: actions.incrementPostLikeCount,
});

export default flowRight(
  withTranslate,
  withFontClassName,
  connect(mapRuntimeToProps),
)(FooterPostsMobile);
