import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactPlayer from '../react-player-wrapper';
import Overlay from 'react-overlays/lib/Overlay';
import { PlayArrow } from '../icons/play-arrow';
import { PlayIcon } from '../icons/play-icon';
import ImageLoader from '../image-loader/index';
import Tooltip from '../tooltip';
import withDeviceType from '../../hoc/with-device-type';
import withEventEmitter from '../../hoc/with-event-emitter';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import { getProvider, buildUrl } from '../../services/video-embed';
import scrollParent from '../../services/scroll-parent';
import styles from './video-embed.scss';

const MAX_WAIT_TIME = 5000;

const getStyle = ({ thumbnail_url } = {}) => ({
  backgroundImage: `url(${thumbnail_url})`,
});

class VideoEmbed extends Component {
  state = {
    isLoading: false,
    isLoaded: false,
    isTooltipVisible: false,
  };

  componentDidMount() {
    this.props.eventEmitter.on('video-start', this.handleVideoStart);
  }

  componentWillUnmount() {
    this.props.eventEmitter.removeListener('video-start', this.handleVideoStart);
    clearTimeout(this.timeout);
  }

  handleMouseEnter = () => {
    this.timeout = setTimeout(() => {
      this.setState({
        isTooltipVisible: true,
      });
    }, 500);
  };

  handleMouseLeave = () => {
    clearTimeout(this.timeout);
    this.setState({
      isTooltipVisible: false,
    });
  };

  setPlayer = player => {
    this.player = player;
  };

  handleVideoStart = player => {
    if (this.player !== player) {
      this.setState({
        isLoading: false,
        isLoaded: false,
      });
    }
  };

  handlePlay = event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ isLoading: true });
    setTimeout(() => this.handleReady(), MAX_WAIT_TIME);
  };

  handleReady = () => {
    if (this.state.isLoading) {
      this.setState({ isLoaded: true });
    }
  };

  handleStart = () => {
    this.props.eventEmitter.emitEvent('video-start', [this.player]);
  };

  renderPlayIcon = () => {
    return (
      <span ref={this.setPlayIconWrapper}>
        <PlayIcon className={styles.icon} />
      </span>
    );
  };

  setPlayIconWrapper = ref => (this.playIconWrapper = ref);

  renderThumbnail() {
    const { isLoading, isLoaded } = this.state;
    const { oembed, isMobile, isDesktop, t, isPlayDisabled } = this.props;
    const handleOverlayClick = isPlayDisabled ? noop : this.handlePlay;
    const provider = getProvider(oembed.video_url);

    return (
      !isLoaded && (
        <div
          className={styles.thumbnail}
          style={getStyle(oembed)}
          onClick={handleOverlayClick}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          data-hook="video"
        >
          <div className={styles.overlay} />
          {!isLoading && isMobile && (
            <div className={styles.button}>
              <PlayArrow className={styles.arrow} /> {t('video-embed.load-video')}
            </div>
          )}
          {!isLoading && isDesktop && this.renderPlayIcon()}
          {provider && provider.isLoaderVisible && isLoading && <ImageLoader type="medium" />}
          <Overlay
            show={isPlayDisabled && this.state.isTooltipVisible}
            placement="top"
            container={scrollParent()}
            target={this.playIconWrapper}
            onHide={this.handleMouseLeave}
            rootClose
          >
            <Tooltip>{t('video-embed.disabled-tooltip')}</Tooltip>
          </Overlay>
        </div>
      )
    );
  }

  renderPlayer() {
    const { isLoading } = this.state;
    const { isDesktop, oembed } = this.props;
    return (
      isLoading && (
        <ReactPlayer
          ref={this.setPlayer}
          className={styles.player}
          width="100%"
          height="100%"
          url={buildUrl(oembed.video_url)}
          onReady={this.handleReady}
          onStart={this.handleStart}
          playing={isDesktop}
          controls
        />
      )
    );
  }

  render() {
    return (
      <div className={styles.videoEmbed}>
        {this.renderPlayer()}
        {this.renderThumbnail()}
      </div>
    );
  }
}

VideoEmbed.propTypes = {
  oembed: PropTypes.object.isRequired,
  eventEmitter: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
  t: PropTypes.func.isRequired,
  isPlayDisabled: PropTypes.bool,
};

export default withTranslate(withDeviceType(withEventEmitter(VideoEmbed)));
