import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Loader from '@wix/communities-forum-client-commons/dist/src/components/loader';
import { connect } from '../../../common/components/runtime-context';
import styles from './global-loader.scss';

class GlobalLoader extends Component {
  render() {
    return this.props.isVisible ? (
      <div data-hook="global-loader" className={styles.container}>
        <Loader />
      </div>
    ) : (
      this.props.children
    );
  }
}

GlobalLoader.propTypes = {
  children: PropTypes.node,
  isVisible: PropTypes.bool,
};

const mapRuntimeToProps = state => ({
  isVisible: state.globalLoader.isVisible,
});

export default flowRight(connect(mapRuntimeToProps))(GlobalLoader);
