import { flowRight, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import AppComponent from '../../components/app';
import AppLoaded from '../../components/app-loaded';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import StatusIndicator from '../../components/status-indicator';
import { getIsCategoriesLoaded } from '../../selectors/is-loaded-selectors';
import { DevPanel } from '../../components/dev-tools';

export class App extends Component {
  state = {
    showDevPanel: false,
  };
  render() {
    const { t, isCategoriesFetchFinished } = this.props;
    const shouldRenderApp = get(this.props.categories, 'length') || isCategoriesFetchFinished;
    const app = shouldRenderApp ? <AppComponent {...this.props} /> : <div>{t('app.loading')}</div>;
    return [
      app,
      <AppLoaded />,
      <StatusIndicator
        toggleDevPanel={() => this.setState({ showDevPanel: !this.state.showDevPanel })}
      />,
      this.state.showDevPanel && <DevPanel />,
    ];
  }
}

App.propTypes = {
  categories: PropTypes.array,
};

const mapRuntimeToProps = state => ({
  categories: state.categories,
  isCategoriesFetchFinished: getIsCategoriesLoaded(state),
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(App);
