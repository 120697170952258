export const transformWixCodeLocation = (location, sectionUrl = '') => {
  const lastPathPart = sectionUrl
    .split('/')
    .filter(part => part)
    .pop();
  const indexOfLastPart = location.path.indexOf(lastPathPart);
  let path = [];
  if (indexOfLastPart !== -1) {
    path = location.path.slice(indexOfLastPart + 1);
  }

  return {
    url: location.url,
    baseUrl: location.baseUrl,
    // path: location.path,
    sectionUrl,
    pathname: '/' + path.join('/'),
    protocol: location.protocol,
    query: location.query,
    hash: location.hash,
  };
};
