import React from 'react';
import {
  hasLoadedConversionUtils,
  loadEditorStateConversionUtils,
} from '../services/editor-state-conversion';

export default function waitForEditorStateConversionUtils(WrappedComponent) {
  if (hasLoadedConversionUtils()) {
    return WrappedComponent;
  } else {
    class WaitForEditorStateConversionUtils extends React.Component {
      state = { hasLoaded: hasLoadedConversionUtils() };
      componentDidMount() {
        if (!hasLoadedConversionUtils()) {
          loadEditorStateConversionUtils().then(() => this.setState({ hasLoaded: true }));
        }
      }
      render() {
        return this.state.hasLoaded ? <WrappedComponent {...this.props} /> : null;
      }
    }

    return WaitForEditorStateConversionUtils;
  }
}
