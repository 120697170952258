import createAction from '../../common/services/create-action';

export const FETCH_COUNTERS_REQUEST = 'counters/FETCH_REQUEST';
export const FETCH_COUNTERS_SUCCESS = 'counters/FETCH_SUCCESS';
export const FETCH_COUNTERS_FAILURE = 'counters/FETCH_FAILURE';

export const fetchCountersRequest = createAction(FETCH_COUNTERS_REQUEST);
export const fetchCountersSuccess = createAction(FETCH_COUNTERS_SUCCESS);
export const fetchCountersFailure = createAction(FETCH_COUNTERS_FAILURE);

export function fetchCounters({ categoryIds, postIds }) {
  return (dispatch, getState, { request }) => {
    dispatch(fetchCountersRequest());

    const promise = request.post(`/counters`, { categoryIds, postIds });
    return promise
      .then(counters => dispatch(fetchCountersSuccess(counters)))
      .catch(() => dispatch(fetchCountersFailure()))
      .then(() => promise);
  };
}
