import { flowRight } from 'lodash';
import React from 'react';
import styles from './login-page.scss';
import EmptyStates from '../../components/empty-states';
import LoginButton from '../../components/login-button';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import { getCategoryFromLocation } from '../../../common/selectors/categories-selectors';
import { connect } from '../../../common/components/runtime-context';
import { isPrivate } from '@wix/communities-forum-client-commons/dist/src/services/category-privacy-utils';

const LoginPage = ({ t, category }) => (
  <EmptyStates
    className={styles.EmptyStates}
    title={t('login-screen.login-required')}
    content={t('login-screen.login-description')}
    type={isPrivate(category) ? 'specific_members_other' : 'members_only'}
  >
    <LoginButton />
  </EmptyStates>
);

const mapRuntimeToProps = state => ({
  category: getCategoryFromLocation(state),
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(LoginPage);
