import { CANCEL } from '@sarunas/redux-debounce';
import createAction from '../../common/services/create-action';
import { shouldSearchByHashtag } from '../services/search-utils';
import { extractHashtagsFromText } from '@wix/communities-forum-client-commons/dist/src/services/hashtag-utils';
import { getSearchPageSorting } from '../selectors/sorting-selectors';
import { getSortQueryParams } from '@wix/communities-forum-client-commons/dist/src/constants/sorting';
import uri from 'urijs';
import { decodeQuery } from '../services/query-encoding';
import { getIsMobile } from '../../common/store/basic-params/basic-params-selectors';
import { getSearchResultsPerPage } from '../constants/pagination';

export const FETCH_SEARCH_REQUEST = 'search/FETCH_REQUEST';
export const FETCH_SEARCH_SUCCESS = 'search/FETCH_SUCCESS';
export const FETCH_SEARCH_FAILURE = 'search/FETCH_FAILURE';
export const FETCH_SEARCH_DEBOUNCED = 'search/FETCH_DEBOUNCED';

export const fetchSearchRequest = createAction(FETCH_SEARCH_REQUEST);
export const fetchSearchSuccess = createAction(FETCH_SEARCH_SUCCESS);
export const fetchSearchFailure = createAction(FETCH_SEARCH_FAILURE);

export default function fetchSearch(query, sort, pagination) {
  return (dispatch, getState, { request }) => {
    dispatch(fetchSearchRequest());

    const state = getState();
    pagination = pagination || {
      size: getSearchResultsPerPage(getIsMobile(state)),
      offset: 0,
    };
    const sorting = sort || getSearchPageSorting(state);
    const sortingConfig = getSortQueryParams(sorting);

    query = decodeQuery(decodeURIComponent(query));
    const path = shouldSearchByHashtag(query)
      ? uri('/search/hashtags').query({
          q: extractHashtagsFromText(query).join(','),
          ...sortingConfig,
          ...pagination,
        })
      : uri('/search').query({ q: query, ...sortingConfig, ...pagination });

    const promise = request(path);

    return promise
      .then(results =>
        dispatch(
          fetchSearchSuccess({
            posts: results.posts,
            users: results.users || [],
            metadata: results.metadata,
          }),
        ),
      )
      .catch(() => dispatch(fetchSearchFailure()))
      .then(() => promise);
  };
}

function fetchSearchDebounced({ query, sort, debounce = 300, pagination }) {
  const action = fetchSearch(query, sort, pagination);
  action.type = FETCH_SEARCH_DEBOUNCED;
  action.meta = { debounce };
  return action;
}

export function fetchSearchWithCancel({
  query,
  sort,
  debounce = 300,
  minQueryLength = 2,
  pagination,
}) {
  if (query.length >= minQueryLength) {
    return fetchSearchDebounced({ query, debounce, sort, pagination });
  }
  return {
    type: CANCEL,
    payload: {
      type: FETCH_SEARCH_DEBOUNCED,
    },
  };
}
