import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import {
  ROLE_OWNER,
  ROLE_ADMIN,
  ROLE_MODERATOR,
} from '@wix/communities-forum-universal/dist/src/services/permissions/permissions-checker';
import { OwnerSmallIcon } from '../icons/owner-small-icon';
import { ModeratorSmallIcon } from '../icons/moderator-small-icon';
import styles from './role-badge.scss';
import { getUserRole } from '@wix/communities-forum-universal/dist/src/services/user-role';

const roleToTranslation = {
  [ROLE_OWNER]: 'role.admin',
  [ROLE_ADMIN]: 'role.admin',
  [ROLE_MODERATOR]: 'role.moderator',
};

const BADGES = {
  [ROLE_OWNER]: OwnerSmallIcon,
  [ROLE_ADMIN]: OwnerSmallIcon,
  [ROLE_MODERATOR]: ModeratorSmallIcon,
};

function mapRoleToTranslation(role, t) {
  const tKey = roleToTranslation[role];
  return tKey ? t(tKey) : null;
}

const RoleBadge = ({ t, user, className }) => {
  const role = getUserRole(user);
  const translatedRole = mapRoleToTranslation(role, t);
  const Icon = BADGES[role];

  if (!Icon) return null;

  return (
    <Icon
      aria-label={t(translatedRole)}
      className={classNames(styles.role, 'forum-icon-fill', className)}
    />
  );
};

RoleBadge.propTypes = {
  user: PropTypes.object.isRequired,
  t: PropTypes.func,
};

export default withTranslate(RoleBadge);
