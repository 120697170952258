import { noop } from 'lodash';
import React, { Component } from 'react';
import { ModalContext } from './modal-context';

export const modalProviderCallbacks = {
  openModal: noop,
  closeModal: noop,
};

/* Dont forget to change reducer!!! */
class ModalProvider extends Component {
  state = {
    openedModals: {},
  };
  constructor(props) {
    super(props);

    modalProviderCallbacks.openModal = this.openModal;
    modalProviderCallbacks.closeModal = this.closeModal;
  }

  componentWillUnmount() {
    modalProviderCallbacks.openModal = noop;
    modalProviderCallbacks.closeModal = noop;
  }

  openModal = (type, props, meta, correlationId) => {
    if (this.state.openedModals[type] && !this.props.allowUpdateToModals.includes(type)) {
      return;
    }
    this.setState(state => ({
      openedModals: {
        ...state.openedModals,
        [type]: { type, props, correlationId, meta },
      },
    }));
  };

  closeModal = ({ type }) => {
    this.setState(state => ({
      openedModals: { ...state.openedModals, [type]: null },
    }));
  };

  render() {
    return <ModalContext.Provider value={this.state}>{this.props.children}</ModalContext.Provider>;
  }
}

ModalProvider.defaultProps = {
  allowUpdateToModals: [],
};

export default ModalProvider;
