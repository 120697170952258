import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import { CSSTransitionGroup } from 'react-transition-group';
import ClearIcon from './clear-icon';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import styles from './search-input.scss';
import { handleEnterKeyUp } from '../../services/accessibility';
import { SearchIcon } from '../icons/search-icon';
import withDividerColor from '../../hoc/with-divider-color';

const CLEAR_ANIMATION_TIMEOUT = 500;

export class SearchInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
    };

    this.setInput = this.setInput.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  setInput(node) {
    this.input = node;
  }

  handleChange(e) {
    this.setValue(e.target.value);
  }

  handleClear() {
    this.setValue('');
  }

  handleClick() {
    if (this.props.onClick) {
      this.props.onClick(this.state.value);
    }
  }

  handleKeyUp(event) {
    if (this.props.onClick && event.keyCode === 13) {
      this.props.onClick(this.state.value);
    } else if (event.keyCode === 27) {
      event.target.blur();
      this.handleClear();
    }
  }

  setValue(value) {
    this.setState({ value }, () => {
      const { onChange } = this.props;
      if (onChange) {
        onChange(this.state.value);
      }
    });
  }

  render() {
    const {
      type,
      className,
      inputClassName,
      iconClassName,
      borderClassName,
      color,
      t,
      isFullWidth,
      showCount,
      count,
      showUnderline,
      dividerColor,
      isBordered,
    } = this.props;
    const { value } = this.state;
    const showClearButton = this.props.showClearButton && value;
    const containerClassName = classNames(styles.container, className, 'search-input', {
      [styles.fullWidth]: isFullWidth,
      [styles.bordered]: isBordered,
    });
    return (
      <div className={containerClassName} style={isBordered && { borderColor: dividerColor }}>
        <div
          className={classNames(styles.contentWrapper, {
            [styles.extraPadding]: showClearButton && showCount,
            [styles[type]]: type,
          })}
          role="search"
          aria-label={t('search-input.placeholder')}
          onKeyUp={handleEnterKeyUp(this.handleClick)}
          tabIndex="0"
        >
          <SearchIcon
            className={classNames(styles.icon, iconClassName)}
            onClick={this.handleClick}
            fill={color}
            data-hook="search-icon"
          />
          <form onSubmit={ev => ev.preventDefault()} autoComplete="off">
            <input
              data-hook="search-input"
              ref={this.setInput}
              className={classNames(styles.input, inputClassName, 'search-input__input')}
              onChange={this.handleChange}
              value={value}
              type="text"
              autoComplete="off"
              placeholder={t('search-input.placeholder')}
              onKeyUp={this.handleKeyUp}
              style={{ color }}
            />
          </form>
          <div className={classNames(styles.spacer, inputClassName, styles.placeholder)}>
            {t('search-input.placeholder')}
          </div>
          {showUnderline && !isBordered && (
            <div
              className={classNames(styles.border, borderClassName)}
              style={{ backgroundColor: color }}
            />
          )}
          {showCount && (
            <div data-hook="search-results-count" className={styles.count}>
              {count}
            </div>
          )}
          <CSSTransitionGroup
            transitionName="clear"
            transitionEnterTimeout={CLEAR_ANIMATION_TIMEOUT}
            transitionLeaveTimeout={CLEAR_ANIMATION_TIMEOUT}
          >
            {showClearButton ? (
              <button
                className={classNames(
                  styles.clear,
                  'search-input__clear-button',
                  'button-hover-fill',
                  iconClassName,
                )}
                onClick={this.handleClear}
                tabIndex="-1"
                data-hook="search-clear-button"
              >
                <ClearIcon className={classNames(styles.clearIcon)} />
              </button>
            ) : null}
          </CSSTransitionGroup>
        </div>
      </div>
    );
  }
}

SearchInput.propTypes = {
  t: PropTypes.func.isRequired,
  type: PropTypes.string,
  isFullWidth: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
  borderClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  color: PropTypes.string,
  showClearButton: PropTypes.bool,
  showCount: PropTypes.bool,
  count: PropTypes.number,
  showUnderline: PropTypes.bool,
  isBordered: PropTypes.bool,
  dividerColor: PropTypes.string,
};

SearchInput.defaultProps = {
  showClearButton: true,
  showUnderline: true,
};

export default flowRight(withDividerColor, withTranslate)(SearchInput);
