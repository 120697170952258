import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SortIcon } from '../icons/sort-icon';
import styles from './mobile-select.scss';

class MobileSelect extends Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value };
  }

  onChange = ev => {
    this.setState({ value: ev.target.value });
    this.props.onChange(ev.target.value);
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.cover}>
          <SortIcon className="forum-icon-fill" />
        </div>
        <select
          data-hook="sorting-select"
          className={styles.select}
          value={this.props.value || this.state.value}
          onChange={this.onChange}
        >
          {this.props.options.map(({ label, value }) => (
            <option key={value} value={value} data-hook={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

MobileSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
};

export default MobileSelect;
