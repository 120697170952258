import { flowRight } from 'lodash';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getSubcategories } from '../../../common/selectors/categories-selectors';
import SubcategoryListItem from './subcategory-list-item';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withDividerColor from '../../hoc/with-divider-color';
import withDeviceType from '../../hoc/with-device-type';
import { HorizontalSeparator } from '../../components/separator';
import styles from './subcategory-list.scss';
import { getIsViewsCountEnabled } from '../../selectors/app-settings-selectors';

class SubcategoryList extends Component {
  componentDidMount() {
    this.fetchSubcategoriesIfNeeded(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.categoryId !== this.props.categoryId) {
      this.fetchSubcategoriesIfNeeded(nextProps);
    }
  }

  fetchSubcategoriesIfNeeded = props => {
    const { categoryId, subcategories, fetchSubcategories } = props;
    if (subcategories.length === 0) {
      fetchSubcategories(categoryId);
    }
  };

  render() {
    const { subcategories, borderWidth, isViewsCountEnabled, dividerColor, isMobile } = this.props;
    if (subcategories.length === 0) {
      return null;
    }

    return (
      <React.Fragment>
        <div className={styles.centeringContainer}>
          <ul
            style={{ borderWidth }}
            className={classNames(
              styles.container,
              'forum-card-border-color',
              'forum-text-color',
              'forum-card-background-color',
            )}
          >
            {subcategories.map((s, i) => (
              <li key={s._id}>
                <SubcategoryListItem isViewsCountEnabled={isViewsCountEnabled} category={s} />
                {subcategories.length - 1 !== i && (
                  <div style={{ borderColor: dividerColor }} className={styles.separator} />
                )}
              </li>
            ))}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

SubcategoryList.propTypes = {
  isMobile: PropTypes.bool,
  categoryId: PropTypes.string,
  dividerColor: PropTypes.string,
  subcategories: PropTypes.array,
  fetchSubcategories: PropTypes.func,
  borderWidth: PropTypes.number,
  isViewsCountEnabled: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  subcategories: getSubcategories(state, ownProps.categoryId),
  isViewsCountEnabled: getIsViewsCountEnabled(state, host.style),
  fetchSubcategories: actions.fetchSubcategories,
});

export default flowRight(
  withCardBorderWidth,
  withDividerColor,
  withDeviceType,
  connect(mapRuntimeToProps),
)(SubcategoryList);
