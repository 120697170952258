import PropTypes from 'prop-types';
import React from 'react';
import EmptyStates from '../empty-states';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';

const AccountSuspended = ({ t, ...props }) => (
  <EmptyStates
    title={t('account-suspended.account-suspended')}
    content={t('account-suspended.contact-owner')}
    type="account_suspended"
    {...props}
  />
);

AccountSuspended.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslate(AccountSuspended);
