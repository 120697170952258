import { connect } from '../../../common/components/runtime-context';
import SearchResultsList from '../../components/search-results-list';
import { getIsSearchLoading } from '../../selectors/is-loading-selectors';
import {
  getSearchResultsCurrentPage,
  getSearchResultsTotal,
} from '../../selectors/search-selectors';
import { getIsMobile } from '../../../common/store/basic-params/basic-params-selectors';

const mapRuntimeToProps = (state, ownProps, actions) => ({
  onPostLikeClick: actions.incrementPostLikeCount,
  onCommentLikeClick: actions.incrementCommentLikeCount,
  isLoading: getIsSearchLoading(state),
  search: pagination => {
    actions.setIsLoading('search', '', true);
    actions.search({ query: ownProps.query, pagination });
  },
  searchResultsTotal: getSearchResultsTotal(state),
  page: getSearchResultsCurrentPage(state, getIsMobile(state)),
});

export default connect(mapRuntimeToProps)(SearchResultsList);
