import { get } from 'lodash';
import createAction from '../../common/services/create-action';
import sanitizeContent from '../services/sanitize-content';
import { extractHashtagsFromContent } from '@wix/communities-forum-client-commons/dist/src/services/hashtag-utils';
import { extractMentionsFromContent } from '@wix/communities-forum-client-commons/dist/src/services/mentions-services';
import { getIsMobile } from '../../common/store/basic-params/basic-params-selectors';
import { getCommentsPerPage } from '../constants/pagination';
import { getEntityCount } from '../selectors/pagination-selectors';
import { getPost } from '../selectors/post-selectors';
import { closeModal } from '../../common/modals/framework/store/modal-actions';
import { MODAL_TYPE_CREATE_COMMENT } from '../components/modals/comment-create-modal/comment-create-modal-type';
import { getLastPage } from '@wix/communities-forum-client-commons/dist/src/services/pagination';
import { navigateWithinForum } from '../../common/actions/navigate-within-forum';
import { getRouteParams } from '../../common/router/router-selectors';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';
import { isExternalHookResolve } from '../../common/services/external-hooks';
import { createExternalHookRejectHandler } from '../../common/services/external-hooks-flow';
export const CREATE_COMMENT_REQUEST = 'comment/CREATE_REQUEST';
export const CREATE_COMMENT_SUCCESS = 'comment/CREATE_SUCCESS';
export const CREATE_COMMENT_FAILURE = 'comment/CREATE_FAILURE';

export const createCommentRequest = createAction(CREATE_COMMENT_REQUEST);
export const createCommentSuccess = createAction(
  CREATE_COMMENT_SUCCESS,
  payload => payload,
  (payload, meta) => meta,
);
export const createCommentFailure = createAction(
  CREATE_COMMENT_FAILURE,
  () => undefined,
  meta => meta,
);

export function createComment(postId, comment) {
  return (dispatch, getState, { request }) => {
    dispatch(createCommentRequest());
    const state = getState();
    const isMobile = getIsMobile(state);
    const postType = get(getPost(state, postId), 'postType');

    const navigateToComment = (comment, prevState) => {
      const entityCount = getEntityCount(prevState, 'comments', postId) + 1; // we add +1 since it's new comment. We use prevState, since new one might not be synced yet
      const lastPage = getLastPage(entityCount, getCommentsPerPage(isMobile));
      const params = getRouteParams(prevState);
      const currentPage = parseInt(params.page, 10) || 1;

      if (currentPage === lastPage) {
        return;
      }

      if (isMobile) {
        dispatch(navigateWithinForum(`/main/comment/${comment._id}`));
      } else {
        dispatch(navigateWithinForum(`/${params.categorySlug}/${params.postSlug}/p-${lastPage}`));
      }
    };

    const commentSanitized = sanitizeContent(comment);
    const promise = request.post(`/posts/${postId}/comments`, {
      ...commentSanitized,
      hashtags: extractHashtagsFromContent(commentSanitized.content),
      mentions: extractMentionsFromContent(commentSanitized.content),
    });

    return promise
      .then(
        comment => {
          dispatch(createCommentSuccess(comment, { isMobile, postType }));
          dispatch(closeModal({ type: MODAL_TYPE_CREATE_COMMENT }));
          navigateToComment(comment, state);
        },
        response => dispatch(createCommentFailure(response)),
      )
      .then(() => promise);
  };
}

export function createCommentReply(postId, commentId, comment) {
  return (dispatch, getState, { request }) => {
    dispatch(createCommentRequest());
    const state = getState();
    const isMobile = getIsMobile(state);
    const postType = get(getPost(state, postId), 'postType');

    const commentSanitized = sanitizeContent(comment);
    const promise = request.post(`/posts/${postId}/comments/${commentId}/replies`, {
      ...commentSanitized,
      hashtags: extractHashtagsFromContent(commentSanitized.content),
      mentions: extractMentionsFromContent(commentSanitized.content),
    });

    return promise
      .then(
        comment => {
          dispatch(closeModal({ type: MODAL_TYPE_CREATE_COMMENT }));
          dispatch(
            createCommentSuccess(comment, {
              paginationId: commentId,
              isReply: true,
              isMobile,
              postType,
            }),
          );
        },
        response => dispatch(createCommentFailure(response)),
      )
      .then(() => promise);
  };
}

export const createCommentPromisified = onBeforeCommentCreateHook =>
  createPromisifiedAction(
    (postId, comment) => {
      if (onBeforeCommentCreateHook.hasHook()) {
        return dispatch =>
          onBeforeCommentCreateHook
            .exec(comment)
            .then(
              r =>
                dispatch(
                  createComment(postId, isExternalHookResolve(r) ? r.payload || comment : comment),
                ),
              createExternalHookRejectHandler(dispatch),
            );
      } else {
        return createComment(postId, comment);
      }
    },
    () => null,
    response => response.status,
  );

export const createCommentReplyPromisified = onBeforeCommentCreateHook =>
  createPromisifiedAction(
    (postId, commentId, comment) => {
      if (onBeforeCommentCreateHook.hasHook()) {
        return dispatch =>
          onBeforeCommentCreateHook
            .exec(comment)
            .then(
              r =>
                dispatch(
                  createCommentReply(
                    postId,
                    commentId,
                    isExternalHookResolve(r) ? r.payload || comment : comment,
                  ),
                ),
              createExternalHookRejectHandler(dispatch),
            );
      } else {
        return createCommentReply(postId, commentId, comment);
      }
    },
    comment => ({ _id: comment._id }),
    response => response.status,
  );
