import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import { getCounter } from '../../selectors/counters-selectors';
import { compactNumber } from '../../services/format-number';
import { getLanguage } from '../../../common/store/basic-params/basic-params-selectors';

class CounterNumber extends React.PureComponent {
  render() {
    const { t, language, tKey, tParam, counterValue } = this.props;
    const compactNumberLocal = compactNumber(language);

    if (counterValue === undefined || counterValue === null) {
      return null;
    }

    return tKey ? t(tKey, { [tParam]: counterValue }) : compactNumberLocal(counterValue);
  }
}

CounterNumber.propTypes = {
  entityId: PropTypes.string,
  initialValue: PropTypes.number,
  tKey: PropTypes.string,
  tParam: PropTypes.string,
  language: PropTypes.string,
  totalPosts: PropTypes.bool,
  totalViews: PropTypes.bool,
  viewCount: PropTypes.bool,
  likeCount: PropTypes.bool,
  commentsOnly: PropTypes.bool,
  totalComments: PropTypes.bool,
  totalReplies: PropTypes.bool,
};

CounterNumber.defaultProps = {
  tParam: 'count',
  totalPosts: false,
  totalViews: false,
  viewCount: false,
  likeCount: false,
  commentsOnly: false,
  totalComments: false,
  totalReplies: false,
};

function extractCounters(
  counters,
  { totalPosts, totalViews, viewCount, likeCount, commentsOnly, totalComments, totalReplies },
) {
  if (!counters) {
    return;
  }

  if (totalPosts) {
    return counters.totalPosts;
  } else if (totalViews) {
    return counters.totalViews;
  } else if (viewCount) {
    return counters.viewCount;
  } else if (likeCount) {
    return counters.likeCount;
  } else if (commentsOnly) {
    return counters.totalComments - (counters.totalReplies || 0);
  } else if (totalComments) {
    return counters.totalComments;
  } else if (totalReplies) {
    return counters.totalReplies;
  } else {
    console.error('[CounterNumber] no counter specified');
  }
}

const mapDispatchToProps = (state, ownProps) => ({
  counterValue:
    extractCounters(getCounter(state, ownProps.entityId), ownProps) || ownProps.initialValue,
  language: getLanguage(state),
});

export default flowRight(connect(mapDispatchToProps), withTranslate)(CounterNumber);
