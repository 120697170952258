import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ArrowDownIcon } from '../icons/arrow-down-icon';
import WithTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import styles from './mobile-select.scss';

class MobileSelect extends Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value };
  }

  onChange = ev => {
    this.setState({ value: ev.target.value });
    this.props.onChange(ev.target.value);
  };

  render() {
    const { label, options, dataHook, t } = this.props;
    return (
      <div className={styles.container} data-hook={dataHook}>
        <div className={styles.cover}>
          {t(label)}
          <ArrowDownIcon className="forum-icon-fill" />
        </div>
        <select className={styles.select} onChange={this.onChange}>
          {options.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

MobileSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
};

MobileSelect.defaultProps = {
  dataHook: 'dropdown-select',
};

export default WithTranslate(MobileSelect);
