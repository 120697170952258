import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import FooterPostsMobile from './footer-posts-mobile';
import FooterPostsDesktop from './footer-posts-desktop';
import Loader from '@wix/communities-forum-client-commons/dist/src/components/loader';
import { getIsFooterPostsLoading } from '../../selectors/is-loading-selectors';
import withDeviceType from '../../hoc/with-device-type';
import { getRelatedAndNewPosts, getNewPosts } from '../../selectors/post-selectors';
import { TYPE_NEW_POSTS, TYPE_RELATED_POSTS } from './types';

const FooterPosts = ({ isMobile, posts, isLoading, prefetchPost, type }) => {
  if (isLoading) {
    return <Loader />;
  }

  if (posts.length === 0) {
    return null;
  }

  return isMobile ? (
    <FooterPostsMobile type={type} posts={posts} />
  ) : (
    <FooterPostsDesktop type={type} posts={posts} prefetchPost={prefetchPost} />
  );
};

FooterPosts.TYPE_NEW_POSTS = TYPE_NEW_POSTS;
FooterPosts.TYPE_RELATED_POSTS = TYPE_RELATED_POSTS;

FooterPosts.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  posts: PropTypes.array,
  prefetchPost: PropTypes.bool,
  type: PropTypes.string,
};

FooterPosts.defaultProps = {
  prefetchPost: false,
  type: TYPE_NEW_POSTS,
};

const mapRuntimeToProps = (state, { type }) => {
  const postsNewRelated = getRelatedAndNewPosts(state);
  const postsNew = getNewPosts(state);

  return {
    isLoading: getIsFooterPostsLoading(state),
    posts: type === TYPE_RELATED_POSTS ? postsNewRelated : postsNew,
    type,
  };
};

export default flowRight(withDeviceType, connect(mapRuntimeToProps))(FooterPosts);
