import React, { Component } from 'react';
import { ModalContext } from './modal-context';

export function withModal(WrappedComponent) {
  return class WithModal extends Component {
    static contextType = ModalContext;
    render() {
      return <WrappedComponent {...this.props} openedModals={this.context.openedModals} />;
    }
  };
}
