import { reduce, zipObject } from 'lodash';
import languages from './forum-languages';

export const MOMENT_LOCALES = {
  ...zipObject(languages, languages),
  tl: 'tl-ph',
  zh: 'zh-cn',
  no: 'nb',
};

const DEFAULT_DATE_FORMAT = 'MMM D';
export const DATE_FORMATS = {
  ...reduce(languages, (acc, value) => ({ ...acc, [value]: DEFAULT_DATE_FORMAT }), {}),
  ko: 'MMM Do',
  ja: 'MMMDo',
};
