import React from 'react';
import MobileSelect from './mobile-select';
import SortingSelectHeadless from './sorting-select-headless';
import PropTypes from 'prop-types';

const SortingSelectMobile = ({ query }) => (
  <SortingSelectHeadless query={query}>
    {({ value, options, onChange }) => (
      <MobileSelect options={options} value={value} initialValue={value} onChange={onChange} />
    )}
  </SortingSelectHeadless>
);

SortingSelectMobile.propTypes = {
  query: PropTypes.string,
};

export default SortingSelectMobile;
