import { trimEnd } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import { getBaseUrl } from '../../../common/store/location/location-selectors';

const SiteLink = ({ to, baseUrl, ...props }) => (
  <a href={`${trimEnd(baseUrl, '/')}${to}`} {...props} />
);

SiteLink.propTypes = {
  to: PropTypes.string.isRequired,
  baseUrl: PropTypes.string,
};

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state),
});

export default connect(mapStateToProps)(SiteLink);
