import {
  LAYOUT_TYPE_CLASSIC,
  LAYOUT_TYPE_PINBOARD,
  LAYOUT_TYPE_COMPACT,
} from '../constants/layout-types';
import {
  CARD_SIZE_SMALL,
  CARD_SIZE_MEDIUM,
  CARD_SIZE_LARGE,
} from '@wix/communities-forum-client-commons/dist/src/constants/card-sizes';

export default function getLayoutName(
  layoutType = LAYOUT_TYPE_PINBOARD,
  cardSize = CARD_SIZE_MEDIUM,
) {
  if (layoutType === LAYOUT_TYPE_CLASSIC) {
    return 'classic';
  }

  if (layoutType === LAYOUT_TYPE_COMPACT) {
    return 'compact';
  }

  switch (cardSize) {
    case CARD_SIZE_SMALL:
      return 'small';
    case CARD_SIZE_MEDIUM:
      return 'medium';
    case CARD_SIZE_LARGE:
      return 'medium';
    default:
      return 'small';
  }
}
