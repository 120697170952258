import { identity, has } from 'lodash';

const isLegacyEntityLoading = entity => entity.getData().isLoading;

const isUploadingImage = entity =>
  entity.getType() === 'wix-draft-plugin-image' && !entity.getData().src;

const isUploadingVideo = entity => {
  const hasRetrievedOembed = has(entity.getData(), 'metadata.oembed');
  const hasUploadedCustomVideo = has(entity.getData(), 'src.pathname');

  return (
    entity.getType() === 'wix-draft-plugin-video' && !(hasRetrievedOembed || hasUploadedCustomVideo)
  );
};

const isUploadingFile = entity =>
  entity.getType() === 'wix-draft-plugin-file-upload' && !has(entity.getData(), 'id');

const hasActiveUploads = contentState =>
  contentState
    .getBlockMap()
    .filter(block => block.getType() === 'atomic')
    .map(block => block.getEntityAt(0))
    .filter(identity)
    .toSetSeq()
    .some(entityKey => {
      const entity = contentState.getEntity(entityKey);
      return (
        isLegacyEntityLoading(entity) ||
        isUploadingImage(entity) ||
        isUploadingVideo(entity) ||
        isUploadingFile(entity)
      );
    });

export default hasActiveUploads;
