import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './mobile-select.scss';
import classNames from 'classnames';
import { ArrowDownIcon } from '../icons/arrow-down-icon';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';

class MobileSelect extends Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value };
  }

  onChange = ev => {
    this.setState({ value: ev.target.value });
    this.props.onChange(ev.target.value);
  };

  render() {
    const { options, t } = this.props;

    const className = classNames(
      styles.container,
      'default-forum-card-background-color',
      'forum-text-color',
      'categories-button',
    );

    const currentValue = this.props.value || this.state.value;
    const currentLabel = options.find(o => o.value === currentValue).label;

    return (
      <div className={className}>
        <div className={styles.optionsContainer}>
          <div className={styles.selectContainer}>
            <span className={styles.sortBy}>
              {t('sorting.sort-by')}&nbsp;&nbsp;{currentLabel}
            </span>
            <span>
              <ArrowDownIcon className="forum-icon-fill" />
            </span>
            <select
              data-hook="sorting-select"
              className={styles.select}
              value={this.props.value || this.state.value}
              onChange={this.onChange}
            >
              {options.map(({ label, value }) => (
                <option key={value} value={value} data-hook={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
}

MobileSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default withTranslate(MobileSelect);
