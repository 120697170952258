import PropTypes from 'prop-types';
import React from 'react';
import { flowRight } from 'lodash';
import { getHeaderTitle } from '../../services/categories-service';
import PageDescription from '../page-description';

const CategoryDescription = ({ category, isCoverVisible }) => {
  return (
    <PageDescription
      title={getHeaderTitle(category)}
      description={category.description}
      cover={category.cover}
      coverType={category.coverType}
      overlayColor={category.overlayColor}
      backgroundColor={category.backgroundColor}
      textColor={category.textColor}
      isCoverVisible={isCoverVisible}
      focusHeaderOnMount
    />
  );
};

CategoryDescription.propTypes = {
  category: PropTypes.object.isRequired,
  isCoverVisible: PropTypes.bool.isRequired,
};

export default CategoryDescription;
