export const isContentStateEmpty = contentState => {
  let result = true;
  if (contentState) {
    const firstBlock = contentState.getFirstBlock();
    result =
      contentState.getBlockMap().size === 1 &&
      !firstBlock.getLength() &&
      firstBlock.getType() === 'unstyled';
  }
  return result;
};
