import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import ProtectedButton from '../../containers/protected-button';
import { EXPERIMENT_POSTPAGE_REVAMP } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import { CommentIcon } from '../icons/comment-icon';
import { CommentIconSmall } from '../icons/comment-icon-small';
import { RepliesIcon } from '../icons/replies-icon';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import withAuth from '../../hoc/with-auth';
import withExperiment from '../../hoc/with-experiment';
import withDeviceType from '../../hoc/with-device-type';
import styles from './reply-button.scss';
import withFontClassName from '../../hoc/with-font-class-name';
import { getIsRegistrationWithApproval } from '../../selectors/forum-data-selectors';

class ReplyButton extends Component {
  render() {
    const {
      t,
      onClick,
      forPublicUser,
      dataHook,
      type,
      contentFontClassName,
      userName,
      isRegistrationWithApproval,
      isForQuestion,
      isMobile,
      isPostPageRevampEnabled,
    } = this.props;

    const Icon =
      type === 'reply'
        ? RepliesIcon
        : isMobile && isPostPageRevampEnabled
        ? CommentIconSmall
        : CommentIcon;

    const className = classNames(
      styles.replyButton,
      contentFontClassName,
      'button-hover-color',
      type === 'reply'
        ? 'forum-icon-fill'
        : isMobile && isPostPageRevampEnabled
        ? 'forum-icon-fill'
        : 'forum-icon-stroke',
      type === 'reply'
        ? 'button-hover-fill'
        : isMobile && isPostPageRevampEnabled
        ? 'button-hover-fill'
        : 'button-hover-stroke',
    );
    const Component = isRegistrationWithApproval ? ProtectedButton : 'button';

    return (
      <Component
        className={className}
        aria-label={`${t('reply-button.reply-to', { userName })}`}
        onClick={forPublicUser(onClick)}
      >
        <Icon className={classNames(styles.replyIcon)} />
        <span data-hook={dataHook} className={dataHook}>
          {t(`comment${isForQuestion ? '.question' : ''}.${type}`)}
        </span>
      </Component>
    );
  }
}

ReplyButton.propTypes = {
  dataHook: PropTypes.string,
  mainColor: PropTypes.string,
  t: PropTypes.func,
  forPublicUser: PropTypes.func,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['reply', 'comment', 'answer']).isRequired,
  contentFontClassName: PropTypes.string,
  userName: PropTypes.string,
  isRegistrationWithApproval: PropTypes.bool,
  isForQuestion: PropTypes.bool,
  isMobile: PropTypes.bool,
};

const mapRuntimeToProps = state => ({
  isRegistrationWithApproval: getIsRegistrationWithApproval(state),
});

export default flowRight(
  withFontClassName,
  withTranslate,
  withAuth,
  withDeviceType,
  withExperiment({ isPostPageRevampEnabled: EXPERIMENT_POSTPAGE_REVAMP }),
  connect(mapRuntimeToProps),
)(ReplyButton);
