import React, { Component } from 'react';
import getDisplayName from 'react-display-name';
import hoistNonReactStatics from 'hoist-non-react-statics';
import RuntimeContext from './runtime-context';

export default mapRuntimeToProps => WrappedComponent => {
  class ConnectedComponent extends Component {
    static contextType = RuntimeContext;
    static displayName = `connect(${getDisplayName(WrappedComponent)})`;

    render() {
      const props = mapRuntimeToProps(
        this.context.state,
        this.props,
        this.context.actions,
        this.context.host,
        this.context.whenWorkerInitialized,
      );
      return <WrappedComponent {...this.props} {...props} />;
    }
  }

  hoistNonReactStatics(ConnectedComponent, WrappedComponent);

  return ConnectedComponent;
};
