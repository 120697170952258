import { mapKeys, upperFirst } from 'lodash';
import classNames from 'classnames';
import { css } from 'emotion';
import tinyColor from 'tinycolor2';
import {
  getPresetColor,
  getSiteColor,
} from '@wix/communities-forum-client-commons/dist/src/services/colors';
import {
  APP_TEXT_COLOR as POST_TEXT_COLOR,
  CARD_BACKGROUND_COLOR,
  BUTTON_COLOR,
  LINKS_HASHTAGS_COLOR,
} from '@wix/communities-forum-client-commons/dist/src/constants/wix-params';
import styles from './theme.scss';
import commentStyles from '../comment/comment.scss';

const createToolbarTheme = (toolbarName, theme) =>
  mapKeys(theme, (value, key) => `${toolbarName}${upperFirst(key)}`);

export default function getTheme({
  style,
  titleFontClassName,
  contentFontClassName,
  readOnly,
  compact,
  isViewer,
  isSeo,
  hasBottomToolbarPlugins,
  type,
}) {
  const textColor = getSiteColor(style, {
    wixParam: POST_TEXT_COLOR,
    presetFallback: 'color-5',
    colorFallback: '#00000',
  });

  const opaqueBackgroundColor = tinyColor(
    getSiteColor(style, {
      wixParam: CARD_BACKGROUND_COLOR,
      presetFallback: 'color-1',
      colorFallback: '#fff',
    }),
  )
    .setAlpha(1)
    .toRgbString();
  const dividerColor = tinyColor(textColor)
    .setAlpha(0.2)
    .toRgbString();
  const activeDividerColor = tinyColor(textColor)
    .setAlpha(0.5)
    .toRgbString();
  const highlightedColor = tinyColor(
    getSiteColor(style, {
      wixParam: BUTTON_COLOR,
      presetFallback: 'color-8',
      colorFallback: '#00000',
    }),
  )
    .setAlpha(1)
    .toRgbString();
  const mentionColor = tinyColor(
    getSiteColor(style, {
      wixParam: BUTTON_COLOR,
      presetFallback: 'color-8',
      colorFallback: '#00000',
    }),
  )
    .setAlpha(0.2)
    .toRgbString();
  const linksColor = tinyColor(
    getSiteColor(style, {
      wixParam: LINKS_HASHTAGS_COLOR,
      presetFallback: 'color-8',
      colorFallback: '#00000',
    }),
  )
    .setAlpha(0.8)
    .toRgbString();
  const modalBorderColor = tinyColor(
    getPresetColor(style.siteColors, 'color-5') || 'rgba(51, 51, 51)',
  )
    .setAlpha(0.2)
    .toRgbString();
  const defaultTextColor = tinyColor(
    getPresetColor(style.siteColors, 'color-5') || 'rgba(51, 51, 51)',
  ).toRgbString();
  const defaultDividerColor = tinyColor(defaultTextColor)
    .setAlpha(0.2)
    .toRgbString();
  const defaultActiveDividerColor = tinyColor(defaultTextColor)
    .setAlpha(0.5)
    .toRgbString();

  const classes = {
    textColor: css`
      color: ${textColor} !important;
    `,
    textBorderColor: css`
      border-color: ${textColor} !important;
    `,
    textBorderRightColor: css`
      border-right-color: ${textColor} !important;
    `,
    textBorderBottomColor: css`
      border-bottom-color: ${textColor} !important;
    `,
    defaultTextColor: css`
      color: ${defaultTextColor} !important;
    `,
    opaqueBackgroundColor: css`
      background-color: ${opaqueBackgroundColor} !important;
    `,
    dividerBorderColor: css`
      border-color: ${dividerColor} !important;
    `,
    dividerBackgroundColor: css`
      background-color: ${dividerColor} !important;
    `,
    defaultDividerBackgroundColor: css`
      background-color: ${defaultDividerColor} !important;
    `,
    defaultDividerBorderColor: css`
      border-color: ${defaultDividerColor} !important;
    `,
    focusedInputBorderColor: css`
      &:focus {
        border-color: ${activeDividerColor} !important;
      }
    `,
    defaultFocusedInputBorderColor: css`
      &:focus {
        border-color: ${defaultActiveDividerColor} !important;
      }
    `,
    highlightedBorderColor: css`
      border-color: ${highlightedColor} !important;
    `,
    highlightedBackgroundColor: css`
      background-color: ${highlightedColor} !important;
    `,
    highlightedColor: css`
      color: ${highlightedColor} !important;
    `,
    mentionBackground: css`
      background: ${mentionColor} !important;
    `,
    modalBorderColor: css`
      border-color ${modalBorderColor} !important;
    `,
  };

  const toolbarButtonWrapperClass = 'rce-toolbar-button-wrapper';
  const toolbarButtonActiveClass = 'rce-toolbar-button-active';
  const defaultToolbarTheme = {
    toolbar: classNames(
      classes.textColor,
      classes.opaqueBackgroundColor,
      classes.defaultDividerBorderColor,
      styles.toolbar,
    ),
    toolbarButton_wrapper: toolbarButtonWrapperClass,
    toolbarButton_active: toolbarButtonActiveClass,
    toolbarButton_icon: classNames(
      classes.textColor,
      css`
        .${toolbarButtonWrapperClass}:hover &,
        .${toolbarButtonWrapperClass}:active &,
        .${toolbarButtonActiveClass} & {
          color: ${highlightedColor} !important;
        }
      `,
    ),
    toolbarDropdownButton_icon: classNames(
      classes.textColor,
      css`
        .${toolbarButtonWrapperClass}:hover &,
        .${toolbarButtonWrapperClass}:active &,
        .${toolbarButtonActiveClass} & {
          color: ${highlightedColor} !important;
        }
      `,
    ),
    toolbarSeparator: classes.dividerBackgroundColor,
    toolbarDropdown_options: classes.opaqueBackgroundColor,
  };

  const mention = classNames(
    classes.textColor,
    classes.mentionBackground,
    css`
      &:hover,
      &:visited,
      &:focus {
        background: ${mentionColor};
        color: ${textColor};
      }
    `,
  );

  return {
    editor: classNames(styles.editor, styles[type]),
    wrapper: classNames(
      styles.wrapper,
      contentFontClassName,
      {
        [styles.readOnly]: readOnly,
        [styles.sizeCompact]: compact,
      },
      css`
        .public-DraftEditorPlaceholder-root {
          color: ${textColor} !important;
        }
      `,
    ),
    desktop: styles.desktop,
    android: styles.android,
    rtl: styles.rtl,
    left: styles.left,
    right: styles.right,
    text: styles.text,
    quote: classNames(styles.quote, 'forum-quote-border-color'),
    headerOne: classNames(titleFontClassName, styles.headerTwo),
    headerTwo: classNames(titleFontClassName, styles.headerTwo),
    headerThree: classNames(titleFontClassName, styles.headerThree),
    orderedList: classNames(styles.text, styles.listItem, styles.orderedListItem),
    orderedListContainer: styles.orderedListContainer,
    unorderedList: classNames(styles.text, styles.listItem, styles.unorderedListItem),
    unorderedListContainer: styles.unorderedListContainer,
    atomic: styles.atomic,

    link: classNames(
      styles.link,
      'forum-links-hashtags-color',
      css`
        color: ${linksColor} !important;
      `,
    ),
    hashtag: classNames(
      styles.hashtag,
      'forum-links-hashtags-color',
      css`
        color: ${linksColor} !important;
      `,
    ),
    sizeFullWidth: classNames(styles.sizeFullWidth, commentStyles.fullWidthImageRCE),
    sizeSmall: styles.sizeSmall,

    pluginContainer: classNames(classes.highlightedBorderColor, styles.pluginContainer),
    pluginContainerReadOnly: classNames(styles.pluginContainerReadOnly),
    panelContainer: classNames(
      classes.opaqueBackgroundColor,
      classes.defaultDividerBorderColor,
      styles.toolbar,
    ),
    hasFocus: classNames(classes.highlightedBorderColor, styles.hasFocus),
    focused: styles.focused,
    code: styles.code,
    codeBlock: styles.codeBlock,
    preview: classNames(isViewer && styles.preview, isSeo && styles.previewSeo),

    ...createToolbarTheme('inline', {
      ...defaultToolbarTheme,
      toolbar_scrollableContainer: styles.toolbar_scrollableContainer,
      toolbarButton_wrapper: classNames(
        defaultToolbarTheme.toolbarButton_wrapper,
        styles.toolbarButton_wrapper,
      ),
      toolbarSeparator: classes.defaultDividerBackgroundColor,
    }),
    ...createToolbarTheme('side', {
      ...defaultToolbarTheme,
      toolbar: classNames(defaultToolbarTheme.toolbar, styles.sideToolbar),
      toolbarButton_wrapper: classNames(styles.side_toolbarButton_wrapper),
      toolbar_floatingIcon: [
        css`
          svg {
            fill: ${textColor};
          }
        `,
        css`
          &:hover svg {
            fill: ${highlightedColor};
          }
        `,
        styles.toolbar_floatingIcon,
      ],
    }),
    ...createToolbarTheme('plugin', {
      ...defaultToolbarTheme,
      toolbar: classNames(
        classes.opaqueBackgroundColor,
        classes.defaultDividerBorderColor,
        styles.toolbar,
      ),
      toolbar_inlinePanel: classes.defaultDividerBorderColor,
      toolbar_scrollableContainer: styles.toolbar_scrollableContainer,
      toolbarSeparator: classes.defaultDividerBackgroundColor,
    }),
    ...createToolbarTheme('mobile', {
      ...defaultToolbarTheme,
      toolbar_fixed: styles.mobileToolbarFixed,
      toolbar_responsiveArrow: classNames(
        classes.opaqueBackgroundColor,
        classes.dividerBorderColor,
      ),
      toolbar_responsiveArrowStart_icon: classes.textBorderColor,
      toolbar_responsiveArrowEnd_icon: classes.textBorderColor,
    }),
    ...createToolbarTheme('footer', {
      ...defaultToolbarTheme,
      toolbarButton_wrapper: classNames(
        defaultToolbarTheme.toolbarButton_wrapper,
        styles.footerToolbarButtonWrapper,
      ),
      toolbar: classNames(
        styles.footerToolbar,
        classes.dividerBorderColor,
        hasBottomToolbarPlugins
          ? null
          : css`
              visibility: hidden;
            `,
      ),
      toolbar_buttons: styles.footerToolbar_buttons,
      toolbar_responsiveArrow: classNames(
        classes.opaqueBackgroundColor,
        classes.dividerBorderColor,
      ),
      toolbar_responsiveArrowStart_icon: classes.textBorderColor,
      toolbar_responsiveArrowEnd_icon: classes.textBorderColor,
    }),

    'Dropdown-root': classNames(contentFontClassName, styles.dropdownRoot),
    'Dropdown-control': classNames(
      classes.textColor,
      styles.dropdownControl,
      css`
        &:hover {
          color: ${highlightedColor};
        }
      `,
    ),
    'Dropdown-menu': classNames(classes.opaqueBackgroundColor, classes.defaultDividerBorderColor),
    'Dropdown-arrow': classNames(
      styles.dropdownArrow,
      css`
        fill: ${textColor};
      `,
    ),
    'Dropdown-option': classNames(
      classes.textColor,
      css`
        &:hover {
          color: ${highlightedColor};
        }
      `,
    ),

    tooltip: classNames(
      contentFontClassName,
      css`
        &:hover::before,
        &:hover::after {
          background-color: ${textColor};
        }
      `,
      css`
        &:hover::before {
          color: ${opaqueBackgroundColor};
        }
      `,
      styles.tooltip,
    ),

    tabs_headers: classes.dividerBorderColor,
    tabs_headers_option: css`
      & + & {
        border-left-color: ${dividerColor};
      }
    `,
    tabs_headers_option_selected: classes.highlightedBorderColor,
    tabs_headers_option_label: classNames(contentFontClassName, styles.tabsHeadersOptionLabel),

    button_primary: classNames(
      contentFontClassName,
      'button-background-color',
      'button-primary-text-color',
      styles.button,
    ),
    button_secondary: classNames(
      contentFontClassName,
      'button-border-color',
      'button-color',
      styles.button,
      styles.buttonSecondary,
    ),

    checkbox_icon: classNames(
      styles.checkboxIcon,
      classes.highlightedBorderColor,
      styles.checkboxIcon,
    ),
    checkbox_icon_checked: classNames(
      styles.checkboxIconChecked,
      classes.highlightedBackgroundColor,
      'button-primary-text-color',
    ),

    inputWithLabel_input: classNames(
      classes.textColor,
      classes.defaultDividerBorderColor,
      classes.defaultFocusedInputBorderColor,
      styles.inputWithLabelInput,
    ),

    linkPanel_Content: classNames(contentFontClassName, classes.textColor),
    linkPanel_textInput: classNames(
      classes.textColor,
      classes.defaultDividerBorderColor,
      css`
        &:focus {
          border-color: ${activeDividerColor};
        }
      `,
      styles.linkPanelTextInput,
    ),
    linkPanel_Cancel: classNames(contentFontClassName, classes.textColor, styles.linkPanel_Cancel),
    linkPanel_enabled: classNames(contentFontClassName, 'button-color', styles.linkPanel_enabled),
    radioGroup: classNames(contentFontClassName, styles.radioGroup, classes.textColor),
    radioGroup_input: classNames(styles.radioGroupInput),
    radioGroup_button: classNames(classes.highlightedColor, styles.radioGroupButton),

    radioGroupHorizontal_title: classNames(contentFontClassName, styles.radioGroupHorizontalTitle),

    slider: classNames('button-color', styles.slider),

    sliderWithInput_label: classNames(contentFontClassName, styles.sliderWithInputLabel),
    sliderWithInput_input: classNames(
      contentFontClassName,
      styles.sliderWithInputInput,
      classes.textColor,
      classes.defaultDividerBorderColor,
    ),

    selectionListOption_selected: 'forum-text-color',

    layoutsSelector_label: classNames(contentFontClassName, styles.layoutsSelectorLabel),
    layoutsSelector_tile_label: classNames(contentFontClassName, styles.layoutsSelectorTitleLabel),
    layoutsSelector_icon_selected: classes.highlightedColor,

    thumbnailPlacementSelector_label: classNames(
      contentFontClassName,
      styles.thumbnailPlacementSelectorLabel,
    ),
    thumbnailPlacementSelector_icon_selected: classes.highlightedColor,

    resizeHandleL: styles.resizeHandleL,
    resizeHandleR: styles.resizeHandleR,

    imageRatioSelector_label: classNames(contentFontClassName, styles.imageRatioSelectorLabel),
    imageRatioSelector_ratioLabel: classNames(
      contentFontClassName,
      styles.imageRatioSelectorRatioLabel,
    ),
    imageRatioSelector_ratioButton: classNames(
      'blog-text-after-background-color',
      styles.imageRatioSelectorRatioButton,
    ),
    imageRatioSelector_ratioButton_selected: classNames(
      'button-background-color',
      styles.imageRatioSelectorRatioButtonSelected,
    ),
    imageRatioSelector_ratioIcon: classNames(
      'forum-text-border-color',
      styles.imageRatioSelectorRatioIcon,
    ),
    imageRatioSelector_ratioIcon_selected: classNames(
      'button-primary-text-border-color',
      styles.imageRatioSelectorRatioIconSelected,
    ),

    settingsPanel_footer: classNames(
      classes.opaqueBackgroundColor,
      classes.dividerBorderColor,
      styles.settingPanelFooter,
    ),
    section: classNames('forum-text-color', contentFontClassName),

    imageSettings: classNames(
      'forum-text-color',
      'default-forum-card-background-color',
      styles.imageSettings,
    ),
    imageSettingsTitle: classNames(titleFontClassName, styles.imageSettingsTitle),

    topBar: classNames(contentFontClassName),
    topBarLink: classNames('forum-text-color', 'button-hover-color'),
    filesButton: classNames('forum-text-color', 'button-hover-color'),
    filesItem: classNames('forum-text-color', classes.dividerBorderColor),
    itemContainer: styles.itemContainer,
    itemContainerSelected: classNames('button-color', styles.itemContainerSelected),

    cameraIcon: 'forum-icon-fill',
    header_text: classNames(titleFontClassName, 'forum-text-color', styles.headerText),
    textInput_input: classNames(
      classes.textColor,
      contentFontClassName,
      classes.defaultFocusedInputBorderColor,
      classes.opaqueBackgroundColor,
      styles.textInputInput,
    ),

    htmlEditPanel_primaryButton: 'button-color',
    htmlEditPanel_secondaryButton: classes.textColor,

    mentionDisabled: mention,
    mention,
    mentionSuggestionsEntryFocused: classNames(
      classes.mentionBackground,
      styles.mentionSuggestionsEntryFocused,
    ),
    mentionSuggestionsEntry: classNames(
      styles.mentionSuggestionsEntry,
      css`
        &:focus {
          background-color: ${mentionColor};
        }
      `,
    ),
    mentionSuggestions: classNames(
      contentFontClassName,
      classes.textColor,
      classes.defaultDividerBorderColor,
      css`
        z-index: 1000 !important;
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1) !important;
        background-color: ${opaqueBackgroundColor} !important;
      `,
    ),
    video_modal_container_small: classNames(
      styles.video_modal_container_small,
      contentFontClassName,
      classes.opaqueBackgroundColor,
      classes.modalBorderColor,
    ),
    video_modal_add_a_Video: classNames(
      styles.video_modal_add_a_Video,
      titleFontClassName,
      'default-desktop-header-text-color',
    ),
    video_modal_header_text: classNames(
      classes.textColor,
      styles.video_modal_text,
      contentFontClassName,
    ),
    video_modal_or_upload_video_from: classNames(
      classes.textColor,
      styles.video_modal_text,
      contentFontClassName,
    ),
    fileInputLabel: classNames(
      classes.highlightedColor,
      styles.video_modal_text,
      contentFontClassName,
    ),
    video_modal_add_button_inMiddle: classNames(
      contentFontClassName,
      'button-background-color',
      'button-primary-text-color',
      styles.button,
    ),

    colorPicker_separator: css`
      background: ${dividerColor};
    `,
    colorPicker_add_color_label: 'button-color',
    colorPicker_button: styles.colorPickerButton,
    colorPicker_button_selected: classNames(
      css`
        &:after {
          border-color: ${textColor} !important;
          top: -3px !important;
          left: -3px !important;
        }
      `,
      styles.colorPickerButtonSelected,
    ),
    colorPickerDialog_separator: classNames(
      classes.dividerBackgroundColor,
      css`
        margin-top: 1px;
      `,
    ),
    colorPickerDialog_buttons: contentFontClassName,
    colorPickerDialog_button: classNames(styles.colorPickerDialogButton, 'forum-text-color'),
    colorPickerDialog_button_update: 'button-color',

    customColorPicker_editable_input_container: classNames(
      contentFontClassName,
      'forum-text-color',
    ),
    customColorPicker_input_container: styles.customColorPickerInputContainer,
    textColorModal: classNames(classes.opaqueBackgroundColor, classes.dividerBorderColor),
    textColorModal_mobile: classes.opaqueBackgroundColor,

    file_upload_container: classNames(
      css`
        border-color: ${dividerColor} !important;

        &:hover,
        &:focus {
          border-color: ${highlightedColor} !important;
        }
      `,
      styles.file_upload_container,
    ),
    file_upload_link: styles.file_upload_link,
    file_upload_icon: classNames(
      styles.file_upload_icon,
      css`
        path {
          fill: ${textColor};
        }
      `,
    ),
    file_loader_icon: classNames(
      styles.file_loader_icon,
      css`
        path,
        use,
        mask {
          fill: ${textColor};
        }
      `,
    ),
    file_upload_type: classNames(styles.file_upload_type, 'forum-text-color'),
    file_upload_name: classNames(styles.file_upload_name, 'forum-text-color'),

    giphy_api_input_modal_search: classNames(styles.giphy_api_input_modal_search),
    giphy_api_input_modal_searchIcon: classNames(styles.giphy_api_input_modal_searchIcon),
    giphy_selecter_trending: 'forum-text-color',
    giphy_modal_arrow_down: classNames(classes.opaqueBackgroundColor, styles.modalArrow),
    giphy_modal_arrow_up: classNames(classes.opaqueBackgroundColor, styles.modalArrow),
    giphy_selecter_infinite_scroll_container: styles.giphy_selecter_infinite_scroll_container,
    giphy_api_input_modal_navbar: classNames(
      styles.giphy_api_input_modal_navbar,
      'button-background-color',
      'forum-text-color',
      titleFontClassName,
    ),
    // emoji plugin overrides
    emojiPreviewModal_headerTitle: classNames(
      'forum-text-color',
      titleFontClassName,
      styles.emojiPreviewModal_headerTitle,
    ),
    emojiPreviewModal_JoyPixelsIcon: 'forum-text-color',
    emoji_modal_arrow_up: classNames(classes.opaqueBackgroundColor, styles.modalArrow),
    emoji_modal_arrow_down: classNames(classes.opaqueBackgroundColor, styles.modalArrow),
    emojiPreviewModal_emojis_groups_container: styles.emojiPreviewModal_emojis_groups_container,

    linkPreview: styles.linkPreview,
  };
}
