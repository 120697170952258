import PropTypes from 'prop-types';
import React, { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import tinyColor from 'tinycolor2';
import withAppSettings from './with-app-settings';
import getDisplayName from '@wix/communities-forum-client-commons/dist/src/services/get-display-name';

export default function withSettingsColor({
  path,
  propName,
  alpha = null,
  siteColorFallback = null,
  siteColorAlpha = null,
  fallbackColor = '',
}) {
  return WrappedComponent => {
    class Wrapper extends Component {
      static displayName = `withSettingsColor(${getDisplayName(WrappedComponent)})`;
      static propTypes = {
        color: PropTypes.object,
        siteColors: PropTypes.object,
      };

      setAlpha = (colorCode, alphaValue) =>
        tinyColor(colorCode)
          .setAlpha(alphaValue)
          .toRgbString();

      getColorValue(color) {
        return color.value && alpha ? this.setAlpha(color.value, alpha) : color.value;
      }

      getFallbackSiteColor = () => {
        const f = this.props.siteColors[siteColorFallback];
        return f && siteColorAlpha ? this.setAlpha(f, siteColorAlpha) : f;
      };

      render() {
        const { color = {}, ...otherProps } = this.props;
        const colorCode = this.getColorValue(color) || this.getFallbackSiteColor() || fallbackColor;

        const props = { ...otherProps };
        props[propName] = colorCode;
        // const props = {
        //   [propName]: colorCode,
        //   ...otherProps,
        // };

        return <WrappedComponent {...props} />;
      }
    }

    hoistNonReactStatics(Wrapper, WrappedComponent);

    return withAppSettings({ color: path })(Wrapper);
  };
}
