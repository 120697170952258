import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './mobile-select-wide.scss';
import { ArrowDownIcon } from '../icons/arrow-down-icon';

class MobileSelectWide extends Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value };
  }

  onChange = ev => {
    this.setState({ value: ev.target.value });
    this.props.onChange(ev.target.value);
  };

  render() {
    const { options } = this.props;
    const value = this.props.value || this.state.value;
    const current = options.find(o => o.value === value);
    return (
      <div className={styles.container}>
        <div className={styles.optionsContainer}>
          <div className={styles.selectContainer}>
            <span>{current.label}</span>
            <span>
              <ArrowDownIcon className="forum-icon-fill" />
            </span>
            <select
              data-hook="sorting-select"
              className={styles.select}
              value={value}
              onChange={this.onChange}
            >
              {options.map(({ label, value }) => (
                <option key={value} value={value} data-hook={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
}

MobileSelectWide.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
};

export default MobileSelectWide;
