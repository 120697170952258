import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import withPermissions from '../../hoc/with-permissions';
import withAuth from '../../hoc/with-auth';
import { MODAL_TYPE_UNFOLLOW_CATEGORY } from '../modals/unfollow-category-modal/unfollow-category-modal-type';

export class FollowCategoryHeadless extends React.Component {
  handleSubscribe = () => {
    const {
      category: { _id },
      subscribeToCategory,
      buttonClicked,
    } = this.props;
    buttonClicked({ action: 'follow_category', flag: 1 });

    subscribeToCategory(_id);
  };

  handleUnsubscribe = () => {
    const {
      category: { _id },
      buttonClicked,
      openModal,
    } = this.props;
    buttonClicked({ action: 'follow_category', flag: 0 });
    openModal(MODAL_TYPE_UNFOLLOW_CATEGORY, { categoryId: _id });
  };

  render() {
    const { canRender, category, forPublicUser, children } = this.props;
    return canRender(
      [
        ['subscribe', 'category', category],
        ['read', 'category', category],
      ],
      ([canSubscribe]) => {
        const action = category.isSubscribed ? this.handleUnsubscribe : this.handleSubscribe;
        return children({
          onClick: canSubscribe ? action : forPublicUser(action),
          isSubscribed: category.isSubscribed,
          canSubscribe,
        });
      },
    );
  }
}

FollowCategoryHeadless.propTypes = {
  children: PropTypes.func.isRequired,
  category: PropTypes.object.isRequired,
  canRender: PropTypes.func.isRequired,
  subscribeToCategory: PropTypes.func.isRequired,
  buttonClicked: PropTypes.func.isRequired,
  forPublicUser: PropTypes.func.isRequired,
  openModal: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  subscribeToCategory: actions.subscribeToCategory,
  openModal: actions.openModal,
  buttonClicked: actions.buttonClicked,
});

export default flowRight(
  connect(mapRuntimeToProps),
  withPermissions,
  withAuth,
)(FollowCategoryHeadless);
