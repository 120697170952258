import { get } from 'lodash';
import { convertFromRaw, convertToRaw } from '../../common/services/editor-state-conversion';
import { RCE_FAILED_UPLOAD_ID } from '../constants/upload';

const isFailedImageUpload = entity =>
  entity.type === 'wix-draft-plugin-image' && get(entity, 'data.src.id') === RCE_FAILED_UPLOAD_ID;

const isFailedVideoUpload = entity =>
  entity.type === 'wix-draft-plugin-video' &&
  get(entity, 'data.src.pathname') === RCE_FAILED_UPLOAD_ID;

const isFailedFileUpload = entity =>
  entity.type === 'wix-draft-plugin-file-upload' && get(entity, 'data.id') === RCE_FAILED_UPLOAD_ID;

const removeFailedUploads = contentState => {
  const rawState = convertToRaw(contentState);

  Object.keys(rawState.entityMap).forEach(key => {
    const entity = rawState.entityMap[key];
    const shouldRemoveEntity =
      isFailedImageUpload(entity) || isFailedVideoUpload(entity) || isFailedFileUpload(entity);

    shouldRemoveEntity && delete rawState.entityMap[key];
  });

  const blockKeysToRemove = rawState.blocks.filter(block => {
    const entityKey = get(block, 'entityRanges[0].key');
    return rawState.entityMap[entityKey];
  });

  rawState.blocks = rawState.blocks.filter(block => !blockKeysToRemove.includes(block.key));

  return convertFromRaw(rawState);
};

export default removeFailedUploads;
