import PropTypes from 'prop-types';
import React from 'react';
import EmptyStates from '../empty-states';
import Button from '../button';
import Link from '../link/internal-link';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';

const NotFound = ({ t, ...props }) => (
  <EmptyStates
    title={t('not-found.nothings-showing-up')}
    content={t('not-found.head-back')}
    type="bad_url"
    {...props}
  >
    <Button component={Link} to="/">
      {t('not-found.back')}
    </Button>
  </EmptyStates>
);

NotFound.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslate(NotFound);
