import { flowRight } from 'lodash';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FilterAndSortButton from '../filter-and-sort-button';
import { connect } from '../../../common/components/runtime-context';
import withMainPageEnabled from '../../hoc/with-main-page-enabled';
import { getCategoryPageSorting } from '../../selectors/sorting-selectors';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import { sortingToTranslations } from '../modals/filter-and-sort-modal/sorting-to-translations';
import styles from './filters-bar-mobile.scss';
import withFontClassName from '../../hoc/with-font-class-name';

class FiltersBarMobile extends Component {
  render() {
    const { sort, contentFontClassName, t } = this.props;

    return (
      <div className={styles.container}>
        <span className={classNames(styles.filterText, contentFontClassName, 'forum-text-color')}>
          {t(sortingToTranslations[sort])}
        </span>
        <FilterAndSortButton shouldShowFilter />
      </div>
    );
  }
}

FiltersBarMobile.propTypes = {
  sort: PropTypes.string,
  currentCategory: PropTypes.object,
  uniquePostTypesInAllCategories: PropTypes.array,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  return {
    sort: getCategoryPageSorting(state, host.style),
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withMainPageEnabled,
  withTranslate,
  withFontClassName,
)(FiltersBarMobile);
