import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import { ArrowDown } from '../icons/arrow-down';
import ActionButton from '../action-button';
import styles from './desktop-select.scss';
import MoreButton from '../more-button';
import withSettingsColor from '../../hoc/with-settings-color';
import WithTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import { APP_TEXT_COLOR_PATH } from '@wix/communities-forum-client-commons/dist/src/constants/wix-params';

export class DesktopSelect extends Component {
  state = {
    isOpen: false,
  };

  showComponent = () => {
    this.setState({ isOpen: true });
  };

  hideComponent = () => {
    this.setState({ isOpen: false });
  };

  render = () => {
    const {
      label,
      options,
      onChange,
      value,
      preTextColor,
      showCurrentOption,
      dataHook,
      t,
    } = this.props;

    const openButton = (
      <div className={classNames(styles.openButton)}>
        <span style={{ color: preTextColor }}>{t(label)}</span>&nbsp;
        <span>{`${options.find(o => o.value === value).label}`}</span>
        <span className={classNames(styles.arrow, { [styles.arrowUp]: this.state.isOpen })}>
          <ArrowDown />
        </span>
      </div>
    );
    return (
      <div data-hook={dataHook}>
        <MoreButton
          ariaLabel={t(label)}
          icon={openButton}
          className={classNames(styles.moreButton)}
          iconClassName={classNames('forum-icon-fill', 'button-hover-color', 'button-hover-fill')}
          onHide={this.hideComponent}
          onShow={this.showComponent}
        >
          {options
            .filter(p => showCurrentOption || (!showCurrentOption && p.value !== value))
            .map(p => (
              <ActionButton onClick={() => onChange(p.value)} key={p.value} dataHook={p.value}>
                <div className={styles.actionText}>
                  <span>{p.label}</span>
                  <span style={{ color: preTextColor }}>{p.info}</span>
                </div>
              </ActionButton>
            ))}
        </MoreButton>
      </div>
    );
  };
}

DesktopSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      info: PropTypes.string,
      value: PropTypes.string.isRequired,
    }),
  ),
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
  preTextColor: PropTypes.string,
  t: PropTypes.func.isRequired,
  showCurrentOption: PropTypes.bool,
};

DesktopSelect.defaultProps = {
  showCurrentOption: true,
  dataHook: 'dropdown-select',
};

export default flowRight(
  withSettingsColor({
    propName: 'preTextColor',
    path: APP_TEXT_COLOR_PATH,
    alpha: 0.6,
    siteColorAlpha: 0.6,
    siteColorFallback: 'color-5',
    fallbackColor: '#2f2e2e',
  }),
  WithTranslate,
)(DesktopSelect);
