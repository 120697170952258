import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import styles from './best-answer.scss';
import IconTooltip from '../icon-tooltip';
import { flowRight } from 'lodash';
import { SolvedIcon } from '../icons/solved-icon';

const BestAnswerDesktop = ({ showIconOnly, t }) => (
  <div className={styles.container} data-hook="best-answer">
    <IconTooltip className={styles.tooltip} text={t('comment.solved.best')}>
      <SolvedIcon className="button-fill" />
    </IconTooltip>
    {!showIconOnly && (
      <span className={classNames(styles.label, 'button-color')}>{t('comment.solved.best')}</span>
    )}
  </div>
);

BestAnswerDesktop.propTypes = {
  t: PropTypes.func,
  showIconOnly: PropTypes.bool,
};

export default flowRight(withTranslate)(BestAnswerDesktop);
