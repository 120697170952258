import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import EmptyStates from '../empty-states';
import Button from '../button';
import withDeviceType from '../../hoc/with-device-type';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import styles from './logged-in-no-access-with-plans-empty-state.scss';
import { getForumTitle } from '../../../common/selectors/forum-data-selectors';

const LoggedInNoAccessWithPlansEmptyState = ({ navigateToPaidPlans, isDesktop, t, forumTitle }) => (
  <EmptyStates
    title={t('private-category-restriction-for-paying-members.title', { forumTitle })}
    content={t('private-category-restriction-for-paying-members.content')}
    type="for_paying_members"
  >
    <Button onClick={navigateToPaidPlans} className={styles.fullWidth} isSmall={isDesktop}>
      {t('private-category-restriction-for-paying-members.cta')}
    </Button>
  </EmptyStates>
);

LoggedInNoAccessWithPlansEmptyState.propTypes = {
  navigateToPaidPlans: PropTypes.func,
  returnTo: PropTypes.string,
  paidPlanIds: PropTypes.array,
  isDesktop: PropTypes.bool,
  t: PropTypes.func,
  forumTitle: PropTypes.string.isRequired,
};

const mapRuntimeToProps = (state, { returnTo, paidPlanIds, t }, actions) => ({
  navigateToPaidPlans: () =>
    actions.navigateToPaidPlans(
      returnTo,
      paidPlanIds,
      t('paid-plans-vertical-status-content.title'),
      t(
        paidPlanIds
          ? 'paid-plans-vertical-status-content.back-to-category'
          : 'paid-plans-vertical-status-content.back-to-forum',
      ),
    ),
  forumTitle: getForumTitle(state),
});

export default flowRight(
  withTranslate,
  connect(mapRuntimeToProps),
  withDeviceType,
)(LoggedInNoAccessWithPlansEmptyState);
