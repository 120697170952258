import { convertFromRaw, convertToRaw } from '../../common/services/editor-state-conversion';

export const convertPostContentToContentState = (post = {}) => {
  if (!post || !post.content) {
    return post;
  }
  return {
    ...post,
    content: convertFromRaw(post.content),
  };
};

export const convertContentStateToContent = (post = {}) => {
  if (!post || !post.content) {
    return post;
  }
  return {
    ...post,
    content: convertToRaw(post.content),
  };
};

export function getPostCharCount(post = {}) {
  const plainText =
    post && post.content && post.content.getPlainText && post.content.getPlainText();
  return plainText ? plainText.length : 0;
}

export const isPostSeen = post => post.isSeen && post.unseenCommentsCount === 0;
