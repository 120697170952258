import React from 'react';
import { DesktopSelect } from '../dropdown-select';
import FilterSelectHeadless from './filter-select-headless';

export const FilterSelectPostTypeDesktop = () => (
  <FilterSelectHeadless>
    {({ value, options, onChange }) => (
      <DesktopSelect
        options={options}
        value={value}
        onChange={onChange}
        label="post-filter.label"
        dataHook="filter-select"
      ></DesktopSelect>
    )}
  </FilterSelectHeadless>
);
