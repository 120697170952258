import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import withFontClassName from '../../hoc/with-font-class-name';
import withDeviceType from '../../hoc/with-device-type';
import { CloseIcon } from '../icons/close-icon';
import styles from './message.scss';
import { MESSAGE_INFO } from '../../../common/messages/framework/messages-types';

const Message = ({
  alignment,
  message,
  messageProps,
  onClose,
  t,
  contentFontClassName,
  isMobile,
}) => {
  const { type = MESSAGE_INFO, isHidden, content } = message;
  const isInfoType = type === MESSAGE_INFO;
  const containerClassName = classNames(
    styles.container,
    styles[type],
    styles[alignment],
    contentFontClassName,
    {
      'button-background-color': isInfoType,
      'button-primary-text-color': isInfoType,
    },
  );
  const style = {
    width: isMobile ? '100%' : '',
    borderRadius: isMobile ? 0 : 5,
  };

  if (isHidden) {
    style.width = 0;
    style.height = 0;
    style.fontSize = 0;
    style.padding = 0;
  }

  const buttonClassName = classNames(
    isMobile ? styles.mobile : '',
    styles.close,
    styles[type],
    'message__close',
    {
      'button-primary-icon-fill': isInfoType,
    },
    isHidden ? styles.hidden : '',
  );

  return (
    <div className={containerClassName} style={style}>
      <div className={styles.content} data-hook="message-content">
        {content(messageProps, t)}
      </div>
      {onClose && (
        <button className={buttonClassName} onClick={onClose}>
          <CloseIcon />
        </button>
      )}
    </div>
  );
};

Message.propTypes = {
  onClose: PropTypes.func,
  message: PropTypes.any,
  messageProps: PropTypes.any,
  alignment: PropTypes.string,
  t: PropTypes.func,
  contentFontClassName: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

Message.defaultProps = {
  message: {},
};

export default flowRight(withFontClassName, withTranslate, withDeviceType)(Message);
