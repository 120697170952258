import React from 'react';
import { Interpolate } from 'react-i18next';
import * as messageTypes from './message-types';
import { FILE_UPLOAD_SIZE_LIMIT_MB } from '@wix/communities-forum-client-commons/dist/src/constants/media-limits';
import {
  MESSAGE_ERROR,
  MESSAGE_IMPORTANT,
  MESSAGE_INFO,
} from '../../../common/messages/framework/messages-types';
import ActivateSubscriptionsLink from '../../containers/activate-subscriptions-link';

export default {
  [messageTypes.ASSETS_ARE_UPLOADING]: {
    content: () => <Interpolate i18nKey="post-form.uploading" useDangerouslySetInnerHTML={true} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_DELETE]: {
    content: () => (
      <Interpolate i18nKey="messages.post-deleted" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_UPDATE]: {
    content: () => (
      <Interpolate i18nKey="messages.post-updated" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_INVALID]: {
    content: () => (
      <Interpolate i18nKey="messages.post-invalid" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_ERROR,
  },
  [messageTypes.POST_ENABLE_COMMENTING]: {
    content: () => (
      <Interpolate i18nKey="messages.commenting-enabled" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_ENABLE_ANSWERING]: {
    content: () => (
      <Interpolate i18nKey="messages.answering-enabled" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_DISABLE_COMMENTING]: {
    content: () => (
      <Interpolate i18nKey="messages.commenting-disabled" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_DISABLE_ANSWERING]: {
    content: () => (
      <Interpolate i18nKey="messages.answering-disabled" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_PIN]: {
    content: () => <Interpolate i18nKey="messages.post-pinned" useDangerouslySetInnerHTML={true} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_UNPIN]: {
    content: () => (
      <Interpolate i18nKey="messages.post-unpinned" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_ADD_SOLVED_COMMENT]: {
    content: () => (
      <Interpolate i18nKey="messages.post-solved-comment-added" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_REMOVE_SOLVED_COMMENT]: {
    content: () => (
      <Interpolate
        i18nKey="messages.post-solved-comment-removed"
        useDangerouslySetInnerHTML={true}
      />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.USER_SUBSCRIBED]: {
    content: () => (
      <Interpolate i18nKey="messages.user-subscribed" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.USER_UNSUBSCRIBED]: {
    content: () => (
      <Interpolate i18nKey="messages.user-unsubscribed" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_SUBSCRIBED]: {
    content: () => (
      <Interpolate i18nKey="messages.post-subscribed" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_UNSUBSCRIBED]: {
    content: () => (
      <Interpolate i18nKey="messages.post-unsubscribed" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_MOVE]: {
    content: () => <Interpolate i18nKey="messages.post-moved" useDangerouslySetInnerHTML={true} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_CHANGE_CATEGORY]: {
    content: () => (
      <Interpolate i18nKey="messages.post-category-changed" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },

  [messageTypes.COMMENT_UPDATE]: {
    content: () => (
      <Interpolate i18nKey="messages.comment-updated" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.ANSWER_UPDATE]: {
    content: () => (
      <Interpolate i18nKey="messages.answer-updated" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.REPLY_UPDATE]: {
    content: () => (
      <Interpolate i18nKey="messages.reply-updated" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.COMMENT_DELETE]: {
    content: () => (
      <Interpolate i18nKey="messages.comment-deleted" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.ANSWER_DELETE]: {
    content: () => (
      <Interpolate i18nKey="messages.answer-deleted" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.REPLY_DELETE]: {
    content: () => (
      <Interpolate i18nKey="messages.reply-deleted" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.COMMENT_INVALID]: {
    content: () => (
      <Interpolate i18nKey="messages.comment-invalid" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_ERROR,
  },
  [messageTypes.REQUIRE_POST_FIELDS]: {
    content: data => {
      const key = data && data.sort().join('-');
      switch (key) {
        case 'title':
          return (
            <Interpolate i18nKey="messages.post-title-required" useDangerouslySetInnerHTML={true} />
          );
        case 'content':
          return (
            <Interpolate
              i18nKey="messages.post-content-required"
              useDangerouslySetInnerHTML={true}
            />
          );
        default:
          return (
            <Interpolate
              i18nKey="messages.post-fields-required"
              useDangerouslySetInnerHTML={true}
            />
          );
      }
    },
    type: MESSAGE_ERROR,
  },
  [messageTypes.CATEGORY_DELETE]: {
    content: () => (
      <Interpolate i18nKey="messages.category-deleted" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.CATEGORY_UPDATE]: {
    content: () => (
      <Interpolate i18nKey="messages.category-updated" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.CATEGORY_SUBSCRIBED]: {
    content: () => (
      <Interpolate i18nKey="messages.category-subscribed" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.CATEGORY_UNSUBSCRIBED]: {
    content: () => (
      <Interpolate i18nKey="messages.category-unsubscribed" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.CATEGORY_UNSUBSCRIBED]: {
    content: () => (
      <Interpolate i18nKey="messages.category-unsubscribed" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.CATEGORY_CREATED]: {
    content: () => (
      <Interpolate i18nKey="messages.category-created" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.MAIN_PAGE_UPDATED]: {
    content: () => (
      <Interpolate i18nKey="messages.main-page-updated" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },

  [messageTypes.DEMO_MODE_SITE]: {
    content: () => (
      <Interpolate i18nKey="messages.demo-mode-site" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.DEMO_MODE_EDITOR]: {
    content: () => (
      <Interpolate i18nKey="messages.demo-mode-editor" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.DEMO_MODE_PREVIEW]: {
    content: () => (
      <Interpolate
        i18nKey="messages.frameless-demo-mode-preview"
        useDangerouslySetInnerHTML={true}
      />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.RCE_FILE_DOWNLOAD_WILL_BEGIN_SHORTLY]: {
    content: () => (
      <Interpolate
        i18nKey="messages.rce-file-download-will-begin-shortly"
        useDangerouslySetInnerHTML={true}
      />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.RCE_FILE_TOO_BIG]: {
    content: () => (
      <Interpolate
        i18nKey="messages.rce-file-too-big"
        useDangerouslySetInnerHTML={true}
        size={`${FILE_UPLOAD_SIZE_LIMIT_MB}MB`}
      />
    ),
    type: MESSAGE_ERROR,
  },
  [messageTypes.RCE_FILE_UPLOAD_LIMITED]: {
    content: () => (
      <Interpolate i18nKey="messages.rce-file-upload-limited" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_ERROR,
  },
  [messageTypes.RCE_FILE_UPLOAD_FAILURE]: {
    content: () => (
      <Interpolate i18nKey="messages.rce-file-upload-failure" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_ERROR,
  },
  [messageTypes.RCE_FILE_SIZE_LIMIT_REACHED]: {
    content: ({ uploadSizeLimit }) => (
      <Interpolate
        i18nKey="messages.rce-file-size-limit-reached"
        size={uploadSizeLimit}
        useDangerouslySetInnerHTML={true}
      />
    ),
    type: MESSAGE_ERROR,
  },
  [messageTypes.RCE_FILE_STORAGE_QUOTA_REACHED]: {
    content: () => (
      <Interpolate i18nKey="messages.site-storage-limited" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_ERROR,
  },
  [messageTypes.POST_LINK_COPIED]: {
    content: () => (
      <Interpolate i18nKey="messages.post-link-copied" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_IMPORTANT,
  },
  [messageTypes.POST_LIKE_SUCCESS]: {
    content: () => (
      <Interpolate
        i18nKey="a11y-lives-messages.post-like-success"
        useDangerouslySetInnerHTML={true}
      />
    ),
    type: MESSAGE_INFO,
    isHidden: true,
  },
  [messageTypes.COMMENT_INCREMENT_LIKE_COUNT_SUCCESS]: {
    content: () => (
      <Interpolate
        i18nKey="a11y-lives-messages.comment-like-success"
        useDangerouslySetInnerHTML={true}
      />
    ),
    type: MESSAGE_INFO,
    isHidden: true,
  },
  [messageTypes.COMMENT_CREATE_SUCCESS]: {
    content: () => (
      <Interpolate
        i18nKey="a11y-lives-messages.comment-publish-success"
        useDangerouslySetInnerHTML={true}
      />
    ),
    type: MESSAGE_INFO,
    isHidden: true,
  },
  [messageTypes.POST_CREATE_SUCCESS]: {
    content: () => (
      <Interpolate
        i18nKey="a11y-lives-messages.post-create-success"
        useDangerouslySetInnerHTML={true}
      />
    ),
    type: MESSAGE_INFO,
    isHidden: true,
  },
  [messageTypes.CATEGORY_RANKS_UPDATE_SUCCESS]: {
    content: () => (
      <Interpolate
        i18nKey="a11y-lives-messages.category-ranks-update-success"
        useDangerouslySetInnerHTML={true}
      />
    ),
    type: MESSAGE_INFO,
    isHidden: true,
  },
  [messageTypes.POST_FORM_TITLE_REQUIRED]: {
    content: () => (
      <Interpolate i18nKey="post-form.require-title" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_INFO,
    isHidden: true,
  },
  [messageTypes.POST_FORM_UPLOAD_IN_PROGRESS]: {
    content: () => <Interpolate i18nKey="post-form.uploading" useDangerouslySetInnerHTML={true} />,
    type: MESSAGE_INFO,
    isHidden: true,
  },
  [messageTypes.SEARCH_SEARCH_SUCCESS]: {
    content: ({ posts }, t) => {
      const results = posts.length;
      if (results) {
        return t('a11y-lives-messages.search-results', { count: results });
      } else {
        return (
          <Interpolate
            i18nKey="a11y-lives-messages.search-no-results"
            useDangerouslySetInnerHTML={true}
          />
        );
      }
    },
    type: MESSAGE_INFO,
    isHidden: true,
  },
  [messageTypes.POST_LIMIT_REACHED]: {
    content: () => (
      <Interpolate i18nKey="messages.post-limit-reached" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_ERROR,
  },
  [messageTypes.COMMENT_LIMIT_REACHED]: {
    content: ({ isReply, isDiscussion }) => {
      const key = isReply
        ? 'messages.reply-limit-reached'
        : isDiscussion
        ? 'messages.comment-limit-reached'
        : 'messages.answer-limit-reached';
      return <Interpolate i18nKey={key} useDangerouslySetInnerHTML={true} />;
    },
    type: MESSAGE_ERROR,
  },
  [messageTypes.ACTIVATE_SUBSCRIPTIONS]: {
    content: () => (
      <Interpolate
        i18nKey="messages.activate-subscriptions"
        link={<ActivateSubscriptionsLink />}
        useDangerouslySetInnerHTML={true}
      />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.EXTERNAL_HOOK_REJECT]: {
    content: ({ message }) => {
      return message;
    },
    type: MESSAGE_ERROR,
  },
  [messageTypes.SITE_STORAGE_FULL]: {
    content: () => (
      <Interpolate i18nKey="messages.site-storage-full" useDangerouslySetInnerHTML={true} />
    ),
    type: MESSAGE_ERROR,
    showWithoutTimeout: true,
  },
};
