import PropTypes from 'prop-types';
import React from 'react';
import InfiniteList from '../infinite-list';
import Comment from '../comment';
import styles from './comment-list.scss';

const THRESHOLD = 400;

const CommentList = ({ comments, onLikeClick, editedCommentId, bestAnswerCommentId }) => (
  <div id="comments" className={styles.commentList}>
    <InfiniteList
      length={comments.length}
      itemRenderer={itemRenderer({
        comments,
        onLikeClick,
        editedCommentId,
        bestAnswerCommentId,
      })}
      threshold={THRESHOLD}
    />
  </div>
);

function itemRenderer({ comments, onLikeClick, editedCommentId, bestAnswerCommentId }) {
  return index => {
    const comment = comments[index];
    const key = comment._id;
    const isBestAnswer = comment._id === bestAnswerCommentId;
    const isLastComment = index === comments.length - 1;
    return [
      renderComment({
        key,
        index,
        comment,
        onLikeClick,
        isBestAnswer,
        bestAnswerCommentId,
        editedCommentId,
        isLastComment,
      }),
    ];
  };
}

/* eslint-disable react/prop-types */
function renderComment({
  key,
  index,
  comment,
  onLikeClick,
  isBestAnswer,
  bestAnswerCommentId,
  editedCommentId,
  isLastComment,
}) {
  return (
    <Comment
      key={key}
      comment={comment}
      isBestAnswer={isBestAnswer}
      onLikeClick={onLikeClick}
      bestAnswerCommentId={bestAnswerCommentId}
      editedCommentId={editedCommentId}
      isLastComment={isLastComment}
    />
  );
}
/* eslint-enable react/prop-types */

CommentList.propTypes = {
  comments: PropTypes.array.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  editedCommentId: PropTypes.string,
  bestAnswerCommentId: PropTypes.string,
};

export default CommentList;
