import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { flowRight } from 'lodash';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import share from '../../services/share';
import PostShareButton from '../post-share-button';
import { MODAL_TYPE_SHARE_POST } from '../modals/share-post-modal/share-post-modal-type';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import styles from './post-social-actions.scss';
import { openNativeShareIfSupported } from '../../../common/services/native-share';
import { getSectionUrl } from '../../../common/store/location/location-selectors';

export class PostSocialActions extends Component {
  sharePost = () => {
    const { sectionUrl, post, category } = this.props;
    openNativeShareIfSupported(
      {
        title: post.title,
        url: `${sectionUrl}/${category.slug}/${post.slug}`,
      },
      () => this.props.openShareLinkModal(),
    );
  };

  handleShare = socialNetwork => {
    share(socialNetwork, this.props.href);
  };

  render() {
    const { className, t } = this.props;

    return (
      <div
        className={classNames(
          className,
          'forum-text-color',
          this.props.titleFontClassName,
          styles.container,
        )}
        tabIndex="-1"
      >
        <div>
          <span className={styles.button}>
            <PostShareButton
              type="link"
              title={t('share-button.via-link')}
              onClick={this.sharePost}
              text={t('share-button.link')}
              contentFontClassName={this.props.contentFontClassName}
            />
          </span>
        </div>
      </div>
    );
  }
}

PostSocialActions.propTypes = {
  post: PropTypes.object,
  category: PropTypes.object,
  className: PropTypes.string,
  openShareLinkModal: PropTypes.func.isRequired,
  titleFontClassName: PropTypes.string,
  contentFontClassName: PropTypes.string,
  sectionUrl: PropTypes.string,
  t: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  return {
    sectionUrl: getSectionUrl(state),
    openShareLinkModal: () => {
      return actions.openModal(MODAL_TYPE_SHARE_POST, {
        isLinkShareFormVisible: false,
        categorySlug: ownProps.category.slug,
        postSlug: ownProps.post.slug,
      });
    },
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withFontClassName,
  withTranslate,
)(PostSocialActions);
