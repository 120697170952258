import React from 'react';

export const SendIcon = (
  { ...props }, //eslint-disable-line
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    stroke="none"
    {...props}
  >
    <path
      fill-rule="evenodd"
      d="M19.211 12.894L6.447 19.276c-.494.247-1.094.047-1.341-.447-.07-.139-.106-.292-.106-.447V15c0-3 8-2 8-3s-8 0-8-3V5.618c0-.552.448-1 1-1 .155 0 .308.036.447.106l12.764 6.382c.494.247.694.847.447 1.341-.096.194-.253.35-.447.447z"
    />
  </svg>
);
