import React from 'react';
import PropTypes from 'prop-types';
import MobileSelect from './mobile-select';
import MobileSelectWide from './mobile-select-wide';
import SortingSelectHeadless from './sorting-select-headless';

const SortingSelectMobile = ({ isFullWidth }) => (
  <SortingSelectHeadless fetchFirstPageOnly>
    {({ value, options, onChange }) => {
      const Component = isFullWidth ? MobileSelectWide : MobileSelect;
      return <Component options={options} value={value} initialValue={value} onChange={onChange} />;
    }}
  </SortingSelectHeadless>
);

SortingSelectMobile.defaults = {
  isFullWidth: false,
};

SortingSelectMobile.propTypes = {
  isFullWidth: PropTypes.bool,
};

export default SortingSelectMobile;
