import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import EmptyStates from '../empty-states';
import withPermissions from '../../hoc/with-permissions';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import styles from './no-posts-filtered.scss';

class NoPostsFiltered extends React.Component {
  render() {
    const { t, category, type, ...rest } = this.props;
    return (
      <EmptyStates
        className={styles.container}
        title={t('no-filtered-posts.title')}
        content={t('no-filtered-posts.reset-your-filter')}
        type={type}
        {...rest}
      ></EmptyStates>
    );
  }
}

NoPostsFiltered.propTypes = {
  category: PropTypes.object,
  t: PropTypes.func,
  type: PropTypes.string,
};

export default flowRight(withPermissions, withTranslate)(NoPostsFiltered);
