import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import RichContentViewer from '../rich-content-viewer';
import withFontClassName from '../../hoc/with-font-class-name';

export function PostContent({ post, type, contentFontClassName } = {}) {
  let postBody;

  if (typeof post.content === 'object') {
    postBody = (
      <div>
        <RichContentViewer
          key={`${post._id}-${post.editedDate}`}
          initialRawState={post.content}
          readOnly
          origin="post"
          type={type}
        />
      </div>
    );
  }

  return (
    <article className={contentFontClassName}>
      <div className="post-content__body" tabIndex="0">
        {postBody}
      </div>
    </article>
  );
}

PostContent.POST_PAGE = 'postPage';

PostContent.propTypes = {
  post: PropTypes.object.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default flowRight(withFontClassName)(PostContent);
