import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import styles from './time-ago.scss';

const weekAgo = () => new Date().getTime() - 1000 * 60 * 60 * 24 * 7;
const isLessThanWeek = time => new Date(time).getTime() > weekAgo();
const isNotCurrentYear = time => new Date().getYear() !== new Date(time).getYear();
const getTimeAgoFormat = time => {
  if (isLessThanWeek(time)) {
    return 'time-relative';
  }
  if (isNotCurrentYear(time)) {
    return 'fullDate';
  }
  return 'date';
};

const TimeAgo = ({ className, time, t }) => (
  <span className={classNames(styles.timeAgo, className, 'time-ago')} data-hook="time-ago">
    {t(getTimeAgoFormat(time), { time })}
  </span>
);

TimeAgo.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func,
  time: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
};

export default withTranslate(TimeAgo);
