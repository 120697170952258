import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import { UPDATE_POST } from '../../constants/interactions';
import PostPageLayout from '../../components/post-page-layout';
import PostForm from '../../components/post-form';
import {
  convertContentStateToContent,
  convertPostContentToContentState,
} from '../../services/post-utils';
import { MODAL_TYPE_DISCARD_POST } from '../../components/modals/discard-post-modal/discard-post-modal-type';
import { getRouteParams } from '../../../common/router/router-selectors';
import { getPostBySlug } from '../../selectors/post-selectors';
import {
  enableBrowserRefreshPrompt,
  disableBrowserRefreshPrompt,
} from '../../../common/services/browser-refresh-prompt-service';
import waitForEditorStateConversionUtils from '../../../common/hoc/wait-for-editor-state-conversion-utils';
import styles from './post-edit-page.scss';

export class PostEditPage extends Component {
  componentDidMount() {
    enableBrowserRefreshPrompt();
  }

  componentWillUnmount() {
    disableBrowserRefreshPrompt();
  }
  handleSubmit = ({ values: post }, fastForm) => {
    const { updatePostPromisified, interactionStarted } = this.props;
    interactionStarted(UPDATE_POST);
    post = convertContentStateToContent(post);

    updatePostPromisified({ ...this.props.post, ...post }).catch(() => {
      fastForm.stopSubmit();
    });
  };

  handleCancel = isModified => {
    const { categorySlug, postSlug } = this.props.params;
    if (isModified) {
      return this.props.openModal(MODAL_TYPE_DISCARD_POST, { categorySlug, postSlug });
    } else {
      return this.props.goBack();
    }
  };

  render() {
    const { post } = this.props;
    if (!post) {
      return null;
    }
    return (
      <PostPageLayout className={classNames(styles.postEditPage, 'forum-card-background-color')}>
        <PostForm
          onSubmit={this.handleSubmit}
          onSubmitButtonClick={submit => submit()}
          onCancel={this.handleCancel}
          formInitialValues={convertPostContentToContentState(post)}
          postId={post._id}
        />
      </PostPageLayout>
    );
  }
}

PostEditPage.propTypes = {
  updatePostPromisified: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  openModal: PropTypes.func,
  submit: PropTypes.func.isRequired,
  categoryId: PropTypes.string,
  post: PropTypes.object,
  params: PropTypes.object,
  interactionStarted: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const params = getRouteParams(state);
  const post = getPostBySlug(state, params.postSlug);

  return {
    params,
    post,
    goBack: () => actions.goBack(),
    openModal: actions.openModal,
    updatePostPromisified: actions.updatePostPromisified,
    interactionStarted: actions.interactionStarted,
  };
};

export default flowRight(
  waitForEditorStateConversionUtils,
  connect(mapRuntimeToProps),
)(PostEditPage);
