import PropTypes from 'prop-types';
import React from 'react';
import styles from './large-spacing-layout.scss';

const LargeSpacingLayout = ({ children }) => <div className={styles.container}>{children}</div>;

LargeSpacingLayout.propTypes = {
  children: PropTypes.node,
};

export default LargeSpacingLayout;
