import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import styles from './mark-all-as-read-button.scss';
import withFontClassName from '../../hoc/with-font-class-name';
import { connect } from '../../../common/components/runtime-context';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_RECENT_ACTIVITY_CATS } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import { CheckMarkIcon } from '../icons/check-mark-icon';

class MarkAllAsReadButton extends Component {
  markPostsAsRead = () => this.props.markPostsAsRead(this.props.category._id);
  render() {
    const { contentFontClassName, className, t } = this.props;

    if (!this.props.isRecentActivityCatsEnabled) {
      return null;
    }
    return (
      <button
        onClick={this.markPostsAsRead}
        className={classNames(
          styles.button,
          'forum-icon-fill',
          'forum-text-color',
          'button-color-hover-fill',
          'button-color-hover',
          contentFontClassName,
          className,
        )}
        data-hook="quick-best-answer"
      >
        <CheckMarkIcon className={styles.buttonIcon} />
        {t('recent-activity.mark-all-as-read')}
      </button>
    );
  }
}

MarkAllAsReadButton.propTypes = {
  t: PropTypes.func,
  className: PropTypes.string,
  markPostsAsRead: PropTypes.func,
  category: PropTypes.object,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  markPostsAsRead: actions.markPostsAsRead,
});

export default flowRight(
  withTranslate,
  withFontClassName,
  connect(mapRuntimeToProps),
  withExperiment({
    isRecentActivityCatsEnabled: EXPERIMENT_RECENT_ACTIVITY_CATS,
  }),
)(MarkAllAsReadButton);
