import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import { connect } from '../../../common/components/runtime-context';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import EmptyStates from '../empty-states';
import Button from '../button';
import ButtonGroup from '../button-group';
import ensureAuth from '../../hoc/ensure-auth';
import withDeviceType from '../../hoc/with-device-type';
import { getForumTitle } from '../../../common/selectors/forum-data-selectors';
import { Interpolate } from 'react-i18next';

const ButtonSecure = ensureAuth(Button);

class NotLoggedInWithPlansEmptyState extends Component {
  render() {
    const { buttonClicked, navigateToPaidPlans, isDesktop, t, forumTitle } = this.props;

    return (
      <EmptyStates
        title={t('not-logged-in-with-plans-empty-state.title', { forumTitle })}
        content={
          <Interpolate
            i18nKey="not-logged-in-with-plans-empty-state.content"
            useDangerouslySetInnerHTML={true}
          />
        }
        type="specific_members_paid_plans"
      >
        <ButtonGroup
          primaryButtonText={t('not-logged-in-with-plans-empty-state.see-plans')}
          primaryButtonProps={{
            isSmall: isDesktop,
            onClick: navigateToPaidPlans,
          }}
          secondaryButtonComponent={ButtonSecure}
          secondaryButtonText={t('not-logged-in-with-plans-empty-state.login')}
          secondaryButtonProps={{
            requestLoginMode: 'login',
            isSmall: isDesktop,
            beforeRequestLogin: () => buttonClicked('login'),
          }}
        />
      </EmptyStates>
    );
  }
}

NotLoggedInWithPlansEmptyState.propTypes = {
  navigateToPaidPlans: PropTypes.func,
  buttonClicked: PropTypes.func.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  t: PropTypes.func,
  forumTitle: PropTypes.string.isRequired,
};

const mapRuntimeToProps = (state, { returnTo, paidPlanIds, t }, actions) => ({
  navigateToPaidPlans: () =>
    actions.navigateToPaidPlans(
      returnTo,
      paidPlanIds,
      t('paid-plans-vertical-status-content.title'),
      t(
        paidPlanIds
          ? 'paid-plans-vertical-status-content.back-to-category'
          : 'paid-plans-vertical-status-content.back-to-forum',
      ),
    ),
  buttonClicked: type => actions.buttonClicked({ name: 'all-categories-hidden', type }),
  forumTitle: getForumTitle(state),
});

export default flowRight(
  withTranslate,
  connect(mapRuntimeToProps),
  withDeviceType,
)(NotLoggedInWithPlansEmptyState);
