import { getForumSectionUrl } from '../services/get-section-url';
import createAction from '../services/create-action';
import { transformWixCodeLocation } from '../store/location/transform-wix-code-location';

export const NAVIGATE_WITHIN_FORUM = 'app/NAVIGATE_WITHIN_FORUM';

const navigateWithinForumAction = createAction(NAVIGATE_WITHIN_FORUM);

export const navigateWithinForum = path => (dispatch, getState, { wixCodeApi }) => {
  const sectionUrl = getForumSectionUrl(wixCodeApi);
  dispatch(
    navigateWithinForumAction({
      path,
      prevPath: transformWixCodeLocation(wixCodeApi.location, sectionUrl).pathname,
    }),
  );
  const pageUrl = wixCodeApi.location.baseUrl;
  const sectionPath = sectionUrl.replace(pageUrl, '');
  return wixCodeApi.location.to(`${sectionPath}${path}`);
};
